import ProductListQuery from 'Query/ProductList.query';
import { LinkedProductsDispatcher as SourceLinkedProductsDispatcher } from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import { getIndexedProduct } from 'Util/Product';

export * from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';

/** @namespace Pwa/Store/LinkedProducts/Dispatcher/LinkedProductsDispatcher */
export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    prepareRequest(product_links) {
        const relatedSKUs = product_links
            .filter((product_link) => product_link.link_type !== 'associated')
            .reduce((links, link) => {
                const { linked_product_sku } = link;

                return [...links, `${linked_product_sku.replace(/ /g, '%20')}`];
            }, []);

        if (relatedSKUs?.length === 0) {
            return;
        }

        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: relatedSKUs,
                        pageSize: 100,
                        builtProducts: 'neq',
                    },
                    pageSize: 100,
                },
                isPlp: true,
                notRequireInfo: true,
            }),
        ];
    }

    _processResponse(data, product_links) {
        const {
            products: { items = {} },
        } = data;

        const indexedBySku = items.reduce((acc, item) => {
            const { sku } = item;
            acc[sku] = getIndexedProduct(item);

            return acc;
        }, {});

        const linkedProducts = product_links.reduce(
            (acc, link) => {
                const { linked_product_sku, link_type } = link;

                if (indexedBySku[linked_product_sku] && acc[link_type]) {
                    acc[link_type].items.push(indexedBySku[linked_product_sku]);

                    acc[link_type].total_count++;
                }

                return acc;
            },
            {
                upsell: { total_count: 0, items: [] },
                related: { total_count: 0, items: [] },
                crosssell: { total_count: 0, items: [] },
                associated: { total_count: 0, items: [] },
            }
        );

        return linkedProducts;
    }
}

export default new LinkedProductsDispatcher();
