export const RELATED_TYPE = 'related';
export const UPSELL_TYPE = 'upsell';
export const MIN_VISIBLE_PRODUCTS = 100;
export const SLIDES_TO_SHOW_MOBILE = 2;
export const SLIDES_TO_SHOW_TABLET = 3;
export const SLIDES_TO_SHOW_DESKTOP = 4;

export const DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    slidesToShow: SLIDES_TO_SHOW_DESKTOP,
    slidesToScroll: SLIDES_TO_SHOW_DESKTOP,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                swipeToSlide: true,
                slidesToShow: SLIDES_TO_SHOW_TABLET,
                slidesToScroll: SLIDES_TO_SHOW_TABLET,
            },
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                slidesToShow: SLIDES_TO_SHOW_MOBILE,
                slidesToScroll: SLIDES_TO_SHOW_MOBILE,
                swipeToSlide: false,
            },
        },
    ],
};
