import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Element, Link } from 'react-scroll';

import AmastyRelatedProducts from 'Component/AmastyRelatedProducts';
import ContentWrapper from 'Component/ContentWrapper';
import ProductLinks from 'Component/ProductLinks';
import {
    AMASTY_BEFORE_UPSELLS_POSITION,
    AMASTY_CONTENT_BOTTOM_POSITION,
    AMASTY_CONTENT_TOP_POSITION,
} from 'Route/ProductPage/ProductPage.config';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import isMobile from 'Util/Mobile';
import { getAmastyRelatedSection } from 'Util/Product/Transform';

import {
    PRODUCT_DETAILS_NAV_DURATION,
    PRODUCT_DETAILS_NAV_OFFSET,
    PRODUCT_DETAILS_NAV_OFFSET_MOBILE,
} from './ProductDetails.config';

import './ProductDetails.style';

/** @namespace Pwa/Component/ProductDetails/Component/ProductDetails */
export class ProductDetails extends PureComponent {
    static propTypes = {
        sections: PropTypes.object,
        areDetailsLoaded: PropTypes.bool,
    };

    componentDidUpdate(prevProps) {
        const { product: { attributes = [] } = {} } = this.props;

        if (attributes?.length && prevProps.product?.attributes?.length !== attributes?.length) {
            this.rerenderTabs();
        }
    }

    rerenderTabs() {
        this.forceUpdate();
    }

    renderNavigationItem(section) {
        if (section && section.shouldTabRender) {
            const showSection = section.shouldTabRender && section.shouldTabRender();
            const sectionHasName = !!section?.name;

            if (showSection && sectionHasName) {
                return (
                    <li key={section.id} block="ProductDetails" elem="NavigationItem">
                        <Link
                            activeClass="active"
                            to={section.id}
                            spy
                            smooth
                            duration={PRODUCT_DETAILS_NAV_DURATION}
                            offset={isMobile.any() ? PRODUCT_DETAILS_NAV_OFFSET_MOBILE : PRODUCT_DETAILS_NAV_OFFSET}
                        >
                            {section.name}
                        </Link>
                    </li>
                );
            }
        }
    }

    renderNavigation(sectionsArray) {
        return (
            <ul block="ProductDetails" elem="Navigation">
                {sectionsArray.map((section) => this.renderNavigationItem(section))}
            </ul>
        );
    }

    renderSection(section) {
        if (section && section.shouldTabRender) {
            const showSection = section.shouldTabRender && section.shouldTabRender();

            if (showSection) {
                return (
                    <Element key={section.id} name={section.id}>
                        <div block="ProductDetails" elem="Section">
                            {section.render()}
                        </div>
                    </Element>
                );
            }
        }

        return null;
    }

    renderSections(sectionsArray) {
        return (
            <div block="ProductDetails" elem="Sections">
                {sectionsArray.map((section) => this.renderSection(section))}
            </div>
        );
    }

    renderTopAmastyRelatedProducts() {
        const { amastyRelatedProducts } = this.props;

        if (!amastyRelatedProducts) {
            return null;
        }

        const topAmastyRelatedProducts = getAmastyRelatedSection(amastyRelatedProducts, AMASTY_CONTENT_TOP_POSITION);

        return <AmastyRelatedProducts sectionData={topAmastyRelatedProducts} />;
    }

    renderBottomAmastyRelatedProducts() {
        const { amastyRelatedProducts } = this.props;

        if (!amastyRelatedProducts) {
            return null;
        }

        const bottomAmastyRelatedProducts = getAmastyRelatedSection(
            amastyRelatedProducts,
            AMASTY_CONTENT_BOTTOM_POSITION
        );

        return <AmastyRelatedProducts sectionData={bottomAmastyRelatedProducts} />;
    }

    renderBeforeUpsellsAmastyRelatedProducts() {
        const { amastyRelatedProducts } = this.props;

        if (!amastyRelatedProducts) {
            return null;
        }

        const beforeUpsellsAmastyRelatedProducts = getAmastyRelatedSection(
            amastyRelatedProducts,
            AMASTY_BEFORE_UPSELLS_POSITION
        );

        return <AmastyRelatedProducts sectionData={beforeUpsellsAmastyRelatedProducts} />;
    }

    render() {
        const { sections, areDetailsLoaded, isArrangementProductDetails } = this.props;

        if (!areDetailsLoaded || !sections || sections.length === 0) {
            return null;
        }

        const sectionsArray = Object.values(sections);

        return (
            <ContentWrapper wrapperMix={{ block: 'ProductDetails', elem: 'Wrapper' }} label={__('Product details')}>
                <div block="ProductDetails" elem="Content">
                    {this.renderNavigation(sectionsArray)}
                    {this.renderSections(sectionsArray)}
                    {this.renderTopAmastyRelatedProducts()}
                    {this.renderBottomAmastyRelatedProducts()}
                    {this.renderBeforeUpsellsAmastyRelatedProducts()}
                    <ProductLinks
                        linkType={RELATED}
                        title={__('Recommended for you')}
                        areDetailsLoaded={areDetailsLoaded}
                        isSlider
                        numberOfProductsToDisplay={999}
                        headingSide="left"
                        isArrangementPageRelated={isArrangementProductDetails}
                    />
                    <ProductLinks
                        linkType={UPSELL}
                        title={__('You might also like')}
                        areDetailsLoaded={areDetailsLoaded}
                        isSlider
                        numberOfProductsToDisplay={999}
                        headingSide="left"
                    />
                </div>
            </ContentWrapper>
        );
    }
}

export default ProductDetails;
