import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Pwa/Component/AskAboutProduct/Form/askAboutProductForm */
export const askAboutProductForm = () => [
    {
        type: FIELD_TYPE.text,
        label: __('Your name'),
        attr: {
            name: 'name',
            placeholder: __('Your name'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FIELD_TYPE.email,
        label: __('E-mail'),
        attr: {
            name: 'email',
            placeholder: __('E-mail'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.email,
            isRequired: true,
        },
    },
    {
        type: FIELD_TYPE.text,
        label: __('Phone number'),
        attr: {
            name: 'phone',
            placeholder: __('Phone number'),
        },
        validateOn: ['onChange'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.phone,
        },
    },
    {
        type: FIELD_TYPE.textarea,
        label: __('Message'),
        attr: {
            name: 'message',
            placeholder: __('Message'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
];

export default askAboutProductForm;
