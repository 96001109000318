import ArrangementProductActions from 'Component/ArrangementProductActions';
import AskAboutProduct from 'Component/AskAboutProduct';
import ContentWrapper from 'Component/ContentWrapper';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { ArrangementProductPage as SourceProductPage } from 'Route/ArrangementProduct/ArrangementProduct.component';
import NoMatchHandler from 'Route/NoMatchHandler';
import { ProductReviews } from 'Route/ProductPage/ProductPage.component';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';

import './ProductSetPage.style';

/** @namespace Pwa/Route/ProductSetPage/Component/ProductSetPage */
export class ProductSetPage extends SourceProductPage {
    renderDesignerTitle() {
        return (
            <h3 block="ProductInformation" elem="Header">
                {__('Producer')}
            </h3>
        );
    }

    renderDesigner() {
        const { isMobile, activeProduct } = this.props;
        const { designer, categories } = activeProduct || {};
        const { first_name, last_name, image, description } = designer || {};
        const { url: categoryUrl } = BreadcrumbsDispatcher.findLongestBreadcrumbs(categories) || {};
        const to = `${categoryUrl}?customFilters=pdp_designer:${encodeURIComponent(
            [first_name, last_name].filter(Boolean).join(' ')
        )}`;

        if (!designer) {
            return null;
        }

        return (
            <div block="ProductInformation" elem="Designer">
                {isMobile && this.renderDesignerTitle()}
                <div block="ProductInformation" elem="DesignerInfo">
                    <Image
                        src={image}
                        alt={[first_name, last_name].filter(Boolean).join(' ')}
                        mix={{ block: 'ProductInformation', elem: 'DesignerImage' }}
                        ratio="custom"
                    />
                    <div block="ProductInformation" elem="DesignerContent">
                        {!isMobile && this.renderDesignerTitle()}
                        <span>{[first_name, last_name].filter(Boolean).join(' ')}</span>
                        {description ? <p>{description}</p> : null}
                    </div>
                </div>
                <Link to={to}>
                    {__('See more sets by')} {[first_name, last_name].filter(Boolean).join(' ')}
                </Link>
            </div>
        );
    }

    renderProductInformation() {
        const { areDetailsLoaded } = this.props;
        const heading = areDetailsLoaded ? __('About this set') : '';

        return (
            <div heading={heading} mix={{ block: 'ProductInformation', elem: 'Container' }}>
                <h2 block="ProductInformation" elem="Header">
                    {heading}
                </h2>
                <div block="ProductInformation" elem="Content">
                    {this.renderDescription()}
                    {this.renderAdvantages()}
                    {this.renderDesigner()}
                </div>
            </div>
        );
    }

    render() {
        const { getLink, dataSource, areDetailsLoaded, setActiveProduct, parameters, setQuantity } = this.props;

        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                    mods={{ ArrangementPage: true, ProductSetPage: true }}
                >
                    <ContentWrapper
                        wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}
                    </ContentWrapper>
                    {this.renderProductInformation()}
                    <ArrangementProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                        setArrangamentProductQty={setQuantity}
                        isProductSet
                    />
                    {this.renderAdditionalSections()}
                    <ProductReviews product={dataSource} areDetailsLoaded={areDetailsLoaded} />
                    {this.renderReviewPopup()}
                    <AskAboutProduct product={dataSource} areDetailsLoaded={areDetailsLoaded} />
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductSetPage;
