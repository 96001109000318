import React from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import DataContainer from 'Util/Request/DataContainer';

import RelatedBuiltProducts from './RelatedBuiltProducts.component';

/** @namespace Pwa/Component/RelatedBuildProducts/RelatedBuiltProducts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Pwa/Component/RelatedBuildProducts/RelatedBuiltProducts/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/RelatedBuildProducts/RelatedBuiltProducts/Container/RelatedBuiltProductsContainer */
export class RelatedBuiltProductsContainer extends DataContainer {
    state = {
        isLoading: true,
        data: [],
    };

    componentDidMount() {
        const { builtProductIds } = this.props;

        this.fetchData(
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsIDArray: builtProductIds,
                    },
                },
                isPlp: true,
                noAttributes: true,
            }),
            ({ products: { items: data = [] } = {} }) => {
                this.setState({
                    isLoading: false,
                    data,
                });
            },
            () => {
                this.setState({
                    isLoading: false,
                    data: undefined,
                });
            }
        );
    }

    containerProps() {
        const { settings } = this.props;
        const { isLoading, data } = this.state;

        return { isLoading, data, settings };
    }

    render() {
        return <RelatedBuiltProducts {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedBuiltProductsContainer);
