import { Suspense } from 'react';

import AmastyBundleProductsList from 'Component/AmastyBundleProductsList';
import AmastyRelatedProducts from 'Component/AmastyRelatedProducts';
import AskAboutProduct from 'Component/AskAboutProduct';
import CmsBlock from 'Component/CmsBlock';
import CmsBlockProvider from 'Component/CmsBlockProvider';
import ContentWrapper from 'Component/ContentWrapper';
import Icons from 'Component/Icons';
import Link from 'Component/Link';
import Loader from 'Component/Loader/Loader.component';
import ProductActions from 'Component/ProductActions';
import ProductDescription from 'Component/ProductDescription';
import ProductDetails from 'Component/ProductDetails';
import ProductHeader from 'Component/ProductHeader';
import ProductPageBlogPosts from 'Component/ProductPageBlogPosts';
import ProductVariants from 'Component/ProductVariants';
import RelatedBuildProducts from 'Component/RelatedBuildProducts';
import Shoppers from 'Component/Shoppers';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatchHandler from 'Route/NoMatchHandler';
import {
    ProductAttributes,
    ProductGallery,
    ProductInformation,
    ProductPage as SourceProductPage,
    ProductReviews,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { isCrawler } from 'Util/Browser/Browser';
import { getAmastyRelatedSection } from 'Util/Product/Transform';

import {
    AMASTY_AFTER_RELATED_POSITION,
    AMASTY_BEFORE_RELATED_POSITION,
    CMS_BLOCK_BOTTOM_ID,
    CMS_BLOCK_BOTTOM_MOBILE_ID,
    PRODUCT_AMASTY_AFTER_RELATED,
    PRODUCT_AMASTY_AFTER_RELATED_ID,
    PRODUCT_AMASTY_BEFORE_RELATED,
    PRODUCT_AMASTY_BEFORE_RELATED_ID,
    PRODUCT_AMASTY_BUNDLE,
    PRODUCT_AMASTY_BUNDLE_ID,
    PRODUCT_ARRANGEMENTS,
    PRODUCT_ARRANGEMENTS_ID,
    PRODUCT_ASK_ABOUT,
    PRODUCT_ASK_ABOUT_ID,
    PRODUCT_ATTACHMENTS,
    PRODUCT_ATTACHMENTS_ID,
    PRODUCT_ATTRIBUTES,
    PRODUCT_ATTRIBUTES_ID,
    PRODUCT_DESCRIPTION,
    PRODUCT_DESCRIPTION_ID,
    PRODUCT_DISCOUNT_CODE,
    PRODUCT_DISCOUNT_CODE_ID,
    PRODUCT_INFORMATION,
    PRODUCT_INFORMATION_ID,
    PRODUCT_RELATED_POSTS,
    PRODUCT_RELATED_POSTS_ID,
    PRODUCT_REVIEWS,
    PRODUCT_REVIEWS_ID,
    SIMPLE_PRODUCT,
} from './ProductPage.config';

import './ProductPage.override.style';

export { ProductGallery, ProductReviews, ProductInformation, ProductAttributes };

/** @namespace Pwa/Route/ProductPage/Component/ProductPage */
export class ProductPage extends SourceProductPage {
    tabMap = {
        [PRODUCT_DISCOUNT_CODE]: {
            id: PRODUCT_DISCOUNT_CODE_ID,
            name: __('Discount code'),
            shouldTabRender: () => {
                const { cartPriceRules: { coupon_code = false } = {} } = this.props;

                return !!coupon_code;
            },

            render: (key) => this.renderProductDiscountCodeTab(key),
        },
        [PRODUCT_AMASTY_BUNDLE]: {
            id: PRODUCT_AMASTY_BUNDLE_ID,
            name: this.props?.amastyBundleProductsTabTitle || __('Buy as bundle'),
            shouldTabRender: () => {
                const { amastyBundleProducts } = this.props;

                return !!amastyBundleProducts?.length;
            },
            render: (key) => this.renderProductBundleTab(key),
        },
        [PRODUCT_INFORMATION]: {
            id: PRODUCT_INFORMATION_ID,
            name: __('About'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key),
        },
        [PRODUCT_ATTRIBUTES]: {
            id: PRODUCT_ATTRIBUTES_ID,
            name: __('Details'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key),
        },
        [PRODUCT_ATTACHMENTS]: {
            id: PRODUCT_ATTACHMENTS_ID,
            name: __('Attachments'),
            shouldTabRender: () => {
                const { attachments } = this.props;
                return !!attachments?.length;
            },
            render: (key) => this.renderProductAttachmentsTab(key),
        },
        [PRODUCT_DESCRIPTION]: {
            id: PRODUCT_DESCRIPTION_ID,
            shouldTabRender: () => true,
            render: (key) => this.renderProductDescription(key),
        },
        [PRODUCT_AMASTY_BEFORE_RELATED]: {
            id: PRODUCT_AMASTY_BEFORE_RELATED_ID,
            shouldTabRender: () => {
                const {
                    amastyRelatedProducts,
                    activeProduct: { type_id },
                } = this.props;

                if (type_id === PRODUCT_ARRANGEMENTS_ID) {
                    return null;
                }

                const beforeRelatedProducts = getAmastyRelatedSection(
                    amastyRelatedProducts,
                    AMASTY_BEFORE_RELATED_POSITION
                );

                return beforeRelatedProducts?.items;
            },
            render: (key) => this.renderProductAmastyBeforeRelatedTab(key),
        },
        [PRODUCT_ARRANGEMENTS]: {
            name: __('Arrangements with this product'),
            shouldTabRender: () => {
                const {
                    activeProduct: { related_built_product_ids },
                } = this.props;

                if (isCrawler()) {
                    return null;
                }

                return related_built_product_ids?.length;
            },
            render: (key) => this.renderProductArrangementsTab(key),
        },
        [PRODUCT_AMASTY_AFTER_RELATED]: {
            id: PRODUCT_AMASTY_AFTER_RELATED_ID,
            shouldTabRender: () => {
                const {
                    amastyRelatedProducts,
                    activeProduct: { type_id },
                } = this.props;

                if (type_id === PRODUCT_ARRANGEMENTS_ID) {
                    return null;
                }

                const afterRelatedProducts = getAmastyRelatedSection(
                    amastyRelatedProducts,
                    AMASTY_AFTER_RELATED_POSITION
                );

                return afterRelatedProducts?.items;
            },
            render: (key) => this.renderProductAmastyAfterRelatedTab(key),
        },
        [PRODUCT_RELATED_POSTS]: {
            id: PRODUCT_RELATED_POSTS_ID,
            name: __('Good to know'),
            shouldTabRender: () => {
                const { relatedPosts } = this.props;

                return relatedPosts?.length;
            },
            render: (key) => this.renderBlogPosts(key),
        },
        [PRODUCT_REVIEWS]: {
            id: PRODUCT_REVIEWS_ID,
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: () => true,
            render: (key) => this.renderProductReviewsTab(key),
        },
        [PRODUCT_ASK_ABOUT]: {
            id: PRODUCT_ASK_ABOUT_ID,
            name: __('Ask about product'),
            shouldTabRender: () => !isCrawler(),
            render: (key) => this.renderAskAboutProductTab(key),
        },
    };

    renderProductDiscountCodeTab(key) {
        const {
            cartPriceRules: { coupon_code = false, description = '', rule_name = '' } = {},
            copyDiscountCodeHandler,
        } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <div block="DiscountCode" elem="Wrapper">
                    <div block="DiscountCode" elem="Icon">
                        <Icons name="code_label" />
                    </div>
                    <div block="DiscountCode" elem="TextWrapper">
                        <p block="DiscountCode" elem="Title">
                            {rule_name}
                        </p>
                        <p block="DiscountCode" elem="Description">
                            {description}
                        </p>
                    </div>
                    <div block="DiscountCode" elem="CodeWrapper">
                        <div block="DiscountCode" elem="Box">
                            <p block="DiscountCode" elem="CodeLabel">
                                {__('Discount code')}:
                            </p>
                            <span block="DiscountCode" elem="Code">
                                {coupon_code}
                            </span>
                        </div>
                        <button onClick={() => copyDiscountCodeHandler(coupon_code)}>
                            <Icons name="copy_code" />
                        </button>
                    </div>
                </div>
            </Suspense>
        );
    }

    renderProductArrangementsTab(key) {
        const {
            activeProduct: { related_built_product_ids },
            settings,
        } = this.props;

        if (isCrawler()) {
            return null;
        }

        return (
            <Suspense fallback={<Loader />} key={key}>
                <RelatedBuildProducts builtProductIds={related_built_product_ids} settings={settings} />
            </Suspense>
        );
    }

    renderBlogPosts(key) {
        const { relatedPosts } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <ProductPageBlogPosts posts={relatedPosts} />
            </Suspense>
        );
    }

    renderDesktopBanner() {
        const {
            activeProduct: { type_id },
            isMobile,
        } = this.props;

        if (isMobile || type_id !== SIMPLE_PRODUCT) {
            return null;
        }

        return <CmsBlock identifier="pdp_banner1_desktop" blockType="pdpBannerDesktop" />;
    }

    renderMobileBanner() {
        const {
            activeProduct: { type_id },
            isMobile,
        } = this.props;

        if (!isMobile || type_id !== SIMPLE_PRODUCT) {
            return null;
        }

        return <CmsBlock identifier="pdp_banner1_mobile" blockType="pdpBannerMobile" />;
    }

    renderProductInformationTab(key) {
        const { dataSource, parameters, areDetailsLoaded, isMobile } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <ProductInformation
                    product={{ ...dataSource, parameters }}
                    areDetailsLoaded={areDetailsLoaded}
                    key={key}
                    isMobile={isMobile}
                />
            </Suspense>
        );
    }

    renderProductAmastyBeforeRelatedTab(key) {
        const { amastyRelatedProducts } = this.props;
        const beforeRelatedProducts = getAmastyRelatedSection(amastyRelatedProducts, AMASTY_BEFORE_RELATED_POSITION);

        return (
            <Suspense fallback={<Loader />} key={key}>
                <AmastyRelatedProducts sectionData={beforeRelatedProducts} />
            </Suspense>
        );
    }

    renderProductAmastyAfterRelatedTab(key) {
        const { amastyRelatedProducts } = this.props;
        const afterRelatedProducts = getAmastyRelatedSection(amastyRelatedProducts, AMASTY_AFTER_RELATED_POSITION);

        return (
            <Suspense fallback={<Loader />} key={key}>
                <AmastyRelatedProducts sectionData={afterRelatedProducts} />
            </Suspense>
        );
    }

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isLoadingAttributes,
            isMobile,
            isVariant,
            activeProduct: { type_id },
            isArrangementBuiltProduct,
            arrangementSrc,
            cartPriceRules,
        } = this.props;

        return (
            <>
                {this.renderDesktopBanner()}
                {this.renderMobileBanner()}
                <ProductHeader
                    getLink={getLink}
                    product={dataSource}
                    parameters={parameters}
                    areDetailsLoaded={areDetailsLoaded}
                    setActiveProduct={setActiveProduct}
                />
                <Suspense
                    fallback={
                        <TextPlaceholder
                            mix={{ block: 'ProductPage', elem: 'Placeholder', mods: { type: 'gallery' } }}
                        />
                    }
                >
                    <ProductGallery
                        product={activeProduct}
                        areDetailsLoaded={areDetailsLoaded}
                        isWithEmptySwitcher={useEmptyGallerySwitcher}
                        showLoader={isVariant}
                        isFloatingArrangementButton={isArrangementBuiltProduct}
                        productType={type_id}
                        arrangementPhotoSrc={arrangementSrc}
                    />
                </Suspense>
                <Suspense fallback={<Loader />}>
                    <div block="ProductAdditionalInfo" elem="Wrapper">
                        {!isMobile && (
                            <ProductAttributes
                                product={activeProduct}
                                areDetailsLoaded={areDetailsLoaded}
                                isLoading={isLoadingAttributes}
                                isProductDetails
                                isSearchable
                            />
                        )}
                        {!isMobile && <ProductVariants product={activeProduct} areDetailsLoaded={areDetailsLoaded} />}
                    </div>
                </Suspense>
                <Suspense
                    fallback={
                        <TextPlaceholder mix={{ block: 'ProductPage', elem: 'Placeholder', mods: { type: 'price' } }} />
                    }
                >
                    <ProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                        cartPriceRules={cartPriceRules}
                    />
                </Suspense>
            </>
        );
    }

    renderProductAttachment(attachment, i) {
        return (
            <div block="ProductAttachment" key={i}>
                <Link
                    to={attachment?.url}
                    title={attachment?.name}
                    target="_blank"
                    block="ProductAttachment"
                    elem="Link"
                >
                    <Icons name="download" fill="#10069F" />
                    <span block="ProductAttachment" elem="Name">
                        {attachment?.name}
                    </span>
                </Link>
            </div>
        );
    }

    renderProductAttachmentsTab(key) {
        const { areDetailsLoaded, attachments } = this.props;
        if (!(areDetailsLoaded && attachments.length)) {
            return null;
        }

        return (
            <Suspense fallback={<Loader />} key={key}>
                <div areDetailsLoaded={areDetailsLoaded} key={key}>
                    <h2 block="AttachmentsSection" elem="Header">
                        {__('Attachments')}
                    </h2>
                    {attachments?.map(this.renderProductAttachment)}
                </div>
            </Suspense>
        );
    }

    renderProductBundleTab(key) {
        const { amastyBundleProducts, amastyMainBundleProduct, areDetailsLoaded, isMobile, isTablet } = this.props;

        if (!(areDetailsLoaded && !!amastyBundleProducts?.length)) {
            return null;
        }

        return (
            <Suspense fallback={<Loader />} key={key}>
                <div areDetailsLoaded={areDetailsLoaded} key={key}>
                    <AmastyBundleProductsList
                        amastyBundleProducts={amastyBundleProducts}
                        mainProduct={amastyMainBundleProduct}
                        isMobile={isMobile}
                        isTablet={isTablet}
                    />
                </div>
            </Suspense>
        );
    }

    renderProductDetails(areDetailsLoaded) {
        const { amastyRelatedProducts, activeProduct } = this.props;

        const { type_id } = activeProduct;
        const isArrangement = type_id === PRODUCT_ARRANGEMENTS_ID;

        if (!areDetailsLoaded) {
            return (
                <div block="ProductPage" elem="DetailsLoader">
                    <TextPlaceholder length="long" />
                </div>
            );
        }

        return (
            <ProductDetails
                sections={this.tabMap}
                areDetailsLoaded={areDetailsLoaded}
                amastyRelatedProducts={!isArrangement && amastyRelatedProducts}
                product={activeProduct}
            />
        );
    }

    renderAdditionalSections() {
        const { areDetailsLoaded, amastyBundleProductsLoaded } = this.props;

        return <>{this.renderProductDetails(areDetailsLoaded && amastyBundleProductsLoaded)}</>;
    }

    renderProductAttributesTab(key) {
        const { activeProduct, areDetailsLoaded } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <ProductAttributes product={activeProduct} areDetailsLoaded={areDetailsLoaded} key={key} isSearchable />
            </Suspense>
        );
    }

    renderProductDescription(key) {
        const { activeProduct, areDetailsLoaded, isMobile } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <ProductDescription
                    product={activeProduct}
                    areDetailsLoaded={areDetailsLoaded}
                    key={key}
                    isMobile={isMobile}
                />
            </Suspense>
        );
    }

    renderBottomCmsBlock() {
        const { activeProduct: { type_id } = {}, isMobile } = this.props;

        const id = isMobile ? CMS_BLOCK_BOTTOM_MOBILE_ID : CMS_BLOCK_BOTTOM_ID;
        const blockType = isMobile ? 'pdpBannerMobileThird' : 'pdpBannerDesktopThird';

        if (type_id !== SIMPLE_PRODUCT) {
            return null;
        }

        return (
            <div block="ProductDetails" elem="CmsBlocksBottom">
                <CmsBlock identifier={id} blockType={blockType} shared />
            </div>
        );
    }

    renderAskAboutProductTab(key) {
        const { activeProduct, areDetailsLoaded } = this.props;

        if (isCrawler()) {
            return null;
        }

        return (
            <Suspense fallback={<Loader />} key={key}>
                <AskAboutProduct product={activeProduct} areDetailsLoaded={areDetailsLoaded} key={key} />
            </Suspense>
        );
    }

    renderShoppersScriptContent() {
        const { activeProduct: { sku } = {} } = this.props;

        return <Shoppers isProductPage productID={sku} />;
    }

    render() {
        return (
            <NoMatchHandler>
                <main block="ProductPage" aria-label="Product page" itemScope itemType="http://schema.org/Product">
                    {this.renderShoppersScriptContent()}
                    <CmsBlockProvider
                        identifiers={[
                            'pdp_banner1_desktop',
                            'pdp_banner1_mobile',
                            'pdp_banner2_desktop',
                            'pdp_banner2_mobile',
                            CMS_BLOCK_BOTTOM_ID,
                            CMS_BLOCK_BOTTOM_MOBILE_ID,
                        ]}
                    >
                        <ContentWrapper
                            wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                            label={__('Main product details')}
                        >
                            {this.renderProductPageContent()}
                        </ContentWrapper>
                        {this.renderAdditionalSections()}
                        {this.renderReviewPopup()}
                        {this.renderBottomCmsBlock()}
                    </CmsBlockProvider>
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPage;
