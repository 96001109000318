import { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import ArrangementProductTags from 'Component/ArrangementProductTags';
import Link from 'Component/Link';
import { MINIMAL_VISIBLE_RATING_PERCENT_VAL } from 'Component/ProductActions/ProductActions.config';
import ProductDiscountLabel from 'Component/ProductDiscountLabel';
import ProductReviewRating from 'Component/ProductReviewRating';
import { formatPrice } from 'Util/Price';
import { getBuiltProductsTotals } from 'Util/Product/Extract';

import { ARRANGEMENT_CMS_LINK, PDP_VISIBLE_TAGS_AMOUNT } from './ArrangementProductPrice.config';

import './ArrangementProductPrice.style';

/** @namespace Pwa/Component/ArrangementProductPrice/Component/ArrangementProductPrice */
export class ArrangementProductPrice extends PureComponent {
    renderProductCounter() {
        const {
            product: { items },
            product,
            quantity,
        } = this.props;

        if (!items) {
            return null;
        }

        const { item_count } = getBuiltProductsTotals({ items, ...product }, quantity);

        return (
            <>
                <div>{__('Arrangement products')}: </div>
                <div block="Product" elem="PriceWrapper" mods={{ type: 'count' }}>
                    {item_count}
                </div>
            </>
        );
    }

    renderRegularPrice() {
        const { product: { items } = {}, product, quantity } = this.props;

        if (!items) {
            return null;
        }

        const { regular_price, currency } = getBuiltProductsTotals({ items, ...product }, quantity);

        return (
            <>
                <div>{__('Catalog price')}:</div>
                <div block="Product" elem="PriceWrapper" mods={{ type: 'regularPrice' }}>
                    {formatPrice(regular_price, currency)}
                </div>
            </>
        );
    }

    renderSpecialPrice() {
        const { product: { items } = {}, product, quantity } = this.props;

        if (!items) {
            return null;
        }

        const { special_price, currency, final_price, discount_value } = getBuiltProductsTotals(
            { items, ...product },
            quantity
        );

        return (
            <>
                <div>{__('Domneo price')}:</div>
                <div
                    block="Product"
                    elem="PriceWrapper"
                    mods={{ type: 'domneoPrice', through: final_price !== special_price, highlighted: !discount_value }}
                >
                    <span>{formatPrice(special_price, currency)}</span>
                </div>
            </>
        );
    }

    renderFinalPrice() {
        const {
            product: { items },
            product,
            quantity,
        } = this.props;

        if (!items) {
            return null;
        }

        const { final_price, special_price, currency, discount_value } = getBuiltProductsTotals(
            { items, ...product },
            quantity
        );

        if (final_price === special_price) {
            return null;
        }

        return (
            <>
                <div>{__('Price for set')}:</div>
                <div block="Product" elem="PriceWrapper" mods={{ type: 'setPrice', highlighted: !!discount_value }}>
                    <span>{formatPrice(final_price, currency)}</span>
                </div>
            </>
        );
    }

    renderAvailability() {
        const {
            product: { items },
            quantity,
            product,
        } = this.props;

        if (!items) {
            return null;
        }

        const { productAvailability } = getBuiltProductsTotals({ items, ...product }, quantity);

        const contactText = productAvailability === 'On request' ? __('On request') : null;

        const availabilityStatus = productAvailability === '24h' ? '24h' : `${productAvailability} ${__('days')}`;

        return (
            <>
                <div>{__('Availability')}:</div>
                <div block="Product" elem="PriceWrapper" mods={{ type: 'availability' }}>
                    {contactText || availabilityStatus}
                </div>
            </>
        );
    }

    renderAddToCartButton() {
        const {
            addToCart,
            quantity,
            activeProduct: product,
            activeProduct: { stock_item: { in_stock } = {} } = {},
        } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!in_stock}
                isIconEnabled={false}
                quantity={quantity}
                product={product}
                isShortButtonText
                customTexts={{
                    add: __('Add selected products to cart'),
                }}
            />
        );
    }

    renderAddToCartActionBlock() {
        return (
            <div block="Product" elem="AddToCartWrapper">
                <div block="Product" elem="ButtonsWrapper">
                    <Link className="Product-AddToCart Button" to={ARRANGEMENT_CMS_LINK}>
                        {__('Order your design for FREE')}
                    </Link>
                </div>
            </div>
        );
    }

    renderDiscount() {
        const {
            product: { items },
            product,
            quantity,
        } = this.props;

        const { discount_price, currency } = getBuiltProductsTotals({ items, ...product }, quantity);

        if (discount_price === 0) {
            return null;
        }

        return (
            <>
                <span block="Product" elem="DiscountTitle">
                    {__('You will save')}
                </span>
                <ProductDiscountLabel discountType="fixed" fixedDiscount={formatPrice(discount_price, currency)} />
            </>
        );
    }

    renderRatingSummary() {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} />;
    }

    renderReviewSection() {
        const {
            product: { rating_summary },
        } = this.props;

        const isRatingVisible = rating_summary > MINIMAL_VISIBLE_RATING_PERCENT_VAL;

        if (!isRatingVisible) {
            return null;
        }

        return (
            <div block="Product" elem="Reviews">
                {isRatingVisible && this.renderRatingSummary()}
            </div>
        );
    }

    renderArrangementTags() {
        const {
            product: { attributes, categories, tags = [] },
        } = this.props;

        if (!tags?.length) {
            return null;
        }

        return (
            <ArrangementProductTags
                attributes={attributes}
                block="Product"
                categories={categories}
                tags={tags}
                visibleTagsAmount={PDP_VISIBLE_TAGS_AMOUNT}
            />
        );
    }

    renderArrangamentPriceSection() {
        return (
            <>
                {this.renderReviewSection()}
                {this.renderArrangementTags()}
                <div block="Product" elem="PriceSection">
                    {this.renderAvailability()}
                    {this.renderProductCounter()}
                    {this.renderRegularPrice()}
                    {this.renderSpecialPrice()}
                    {this.renderFinalPrice()}
                    {this.renderDiscount()}
                </div>
                {this.renderAddToCartButton()}
                {this.renderAddToCartActionBlock()}
            </>
        );
    }

    renderSummaryPrice() {
        return (
            <div block="Product" elem="PriceSection">
                {this.renderRegularPrice()}
                {this.renderSpecialPrice()}
                {this.renderFinalPrice()}
                {this.renderDiscount()}
            </div>
        );
    }

    render() {
        const { isSummaryPrice, isImageZoomPopupActive } = this.props;

        if (isImageZoomPopupActive) {
            return null;
        }

        if (isSummaryPrice) {
            return (
                <article block="Product" elem="PriceWrapper">
                    {this.renderSummaryPrice()}
                </article>
            );
        }

        return (
            <article block="Product" elem="PriceWrapper">
                {this.renderArrangamentPriceSection()}
            </article>
        );
    }
}

export default ArrangementProductPrice;
