import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer,
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';

import { MIN_VISIBLE_PRODUCTS } from './ProductLinks.config';

export { mapDispatchToProps };

/** @namespace Pwa/Component/ProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductLinks/Container/ProductLinksContainer */
export class ProductLinksContainer extends SourceProductLinksContainer {
    static defaultProps = {
        numberOfProductsToDisplay: MIN_VISIBLE_PRODUCTS,
        areDetailsLoaded: true,
        isArrangementPageRelated: false,
    };

    containerProps() {
        const { device, isArrangementPageRelated } = this.props;
        return {
            ...super.containerProps(),
            device,
            isArrangementPageRelated,
        };
    }
}

export default withReducers({
    LinkedProductsReducer,
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
