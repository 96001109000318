import CartQuery from 'Query/Cart.query';
import { CartDispatcher as SourceCartDispatcher } from 'SourceStore/Cart/Cart.dispatcher';
import { applyInactiveCouponToCart } from 'Store/Cart/Cart.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

/**
 * Breadcrumbs Dispatcher
 * @class CartDispatcher
 * @namespace Pwa/Store/Cart/Dispatcher/CartDispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async applyCouponToCart(dispatch, couponCode) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return;
            }

            const { applyCoupon: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getApplyCouponMutation(couponCode, guestQuoteId)
            );

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was applied!')));
        } catch (error) {
            dispatch(applyInactiveCouponToCart(couponCode));
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }

    async removeAllProductsFromCart(dispatch) {
        try {
            const guestQuoteId = getGuestQuoteId();

            await fetchMutation(CartQuery.removeAllProductsFromCart(guestQuoteId));

            const { cartData = {} } = await fetchQuery(CartQuery.getCartQuery(guestQuoteId));

            this._updateCartData(cartData, dispatch);
        } catch (e) {
            dispatch(showNotification('error', __('Error removing cart products!')));

            return Promise.reject();
        }
    }
}

export default new CartDispatcher();
