import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            'bok_identifier',
            'total_saves',
            'subtotal_with_discount_incl_tax',
            this._getPromotions(),
        ];
    }

    _getPromotions() {
        return new Field('promotions').addFieldList(this._getPromotionFields());
    }

    _getPromotionFields() {
        return [
            'active_for_item',
            'coupon_code',
            'equal_promo_exceed',
            'image_url',
            'promotion_description',
            'promotion_url',
            'promotion_value',
            'salesrule_id',
        ];
    }

    _getCartItemFields() {
        return [
            ...super._getCartItemFields(),
            'built_parent_product_id',
            'row_total_with_discount',
            this._getPromotions(),
            this._getStockAvailability(),
        ];
    }

    _getStockAvailability() {
        return new Field('stock_availability').addFieldList(this._getStockAvailabilityFields());
    }

    _getStockAvailabilityFields() {
        return ['label', 'qty'];
    }

    _getProductField() {
        return new Field('product').addFieldList([
            ...ProductListQuery._getCartProductInterfaceFields(),
            this._getPriceRange(),
        ]);
    }

    _getPriceRange() {
        return new Field('price_range').addField(this._getMaximumPrice());
    }

    _getMaximumPrice() {
        return new Field('maximum_price').addField(this._getRegularPrice());
    }

    _getRegularPrice() {
        return new Field('regular_price').addFieldList(['value']);
    }

    getShareCart(sharedCartId, targetCartId) {
        return new Field('shareCart')
            .addArgument('sharedCartId', 'String!', sharedCartId)
            .addArgument('targetCartId', 'String!', targetCartId)
            .addField(this._getSharedCartItemsField());
    }

    _getSharedCartItemsField() {
        return new Field('items').addFieldList(['id']);
    }

    removeAllProductsFromCart(guestCartId) {
        const mutation = new Field('removeAllProductsFromCart').addArgument('guestCartId', 'String!', guestCartId);

        return mutation;
    }

    getCartQuery(quoteId) {
        const query = new Field('getCartForCustomer').addFieldList(this._getCartTotalsFields()).setAlias('cartData');

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId || '');
        }

        return query;
    }
}

export default new CartQuery();
