import { getInitialState } from 'SourceStore/Product/Product.reducer';
import { getIndexedAttributes, getIndexedProduct } from 'Util/Product';

import { APPEND_PRODUCT_DETAILS, UPDATE_PRODUCT_DETAILS } from './Product.action';

/** @namespace Pwa/Store/Product/Reducer/ProductReducer */
export const ProductReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_DETAILS: {
            const { product } = action;

            return {
                ...state,
                product: getIndexedProduct(product),
            };
        }

        case APPEND_PRODUCT_DETAILS: {
            const { product } = action;

            const indexedAttributes = Array.isArray(product?.attributes)
                ? getIndexedAttributes(product?.attributes)
                : product?.attributes;

            const insertOldIfDataEmpty = (attrKey, data) =>
                (typeof product[attrKey] === 'object' && Object.keys(product[attrKey]).length === 0) ||
                typeof product[attrKey] === 'undefined'
                    ? state.product[attrKey]
                    : data;

            return {
                ...state,
                product: {
                    ...state.product,
                    ...getIndexedProduct(product),
                    attributes: insertOldIfDataEmpty('attributes', indexedAttributes),
                },
            };
        }
        default:
            return state;
    }
};

export default ProductReducer;
