import Icons from 'Component/Icons';
import { NoMatch as SourceNoMatch } from 'SourceRoute/NoMatch/NoMatch.component';

import './NoMatch.override.style';

/** @namespace Pwa/Route/NoMatch/Component/NoMatch */
export class NoMatch extends SourceNoMatch {
    render() {
        return (
            <div block="NoMatch">
                <Icons name="no_match" />
                <div block="NoMatch" elem="NoMatchContainerInfo">
                    <span block="NoMatch" elem="Info">
                        {__('The page you are requested does not exist')}
                    </span>
                </div>
            </div>
        );
    }
}

export default NoMatch;
