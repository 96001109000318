import { withRouter } from 'react-router';
import { Element } from 'react-scroll';

import AddToCart from 'Component/AddToCart';
import ArrangementProductPrice from 'Component/ArrangementProductPrice';
import CmsBlock from 'Component/CmsBlock';
import GroupedProductList from 'Component/GroupedProductList';
import Icons from 'Component/Icons';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ProductActions as SourceProductActions } from 'SourceComponent/ProductActions/ProductActions.component';
import { isCrawler, isSSR } from 'Util/Browser';
import { getBuiltProductsTotals } from 'Util/Product/Extract';

import './ArrangementProductActions.style';

/** @namespace Pwa/Component/ArrangementProductActions/Component/ArrangementProductActions */
export class ArrangementProductActions extends SourceProductActions {
    renderGroupedOptions() {
        const {
            product,
            product: { type_id: typeId },
            setQuantity,
            quantity,
        } = this.props;

        if (typeId !== PRODUCT_TYPE.grouped && typeId !== PRODUCT_TYPE.built) {
            return null;
        }

        return (
            <div block={this.className} elem="GroupedItems">
                <h2>{__('Products list')}</h2>
                <GroupedProductList product={product} quantity={quantity} setQuantity={setQuantity} />
            </div>
        );
    }

    renderAvailability() {
        const {
            product: { items },
            quantity,
            product,
        } = this.props;

        if (!items) {
            return null;
        }

        const { productAvailability } = getBuiltProductsTotals({ items, ...product }, quantity);

        const contactText =
            productAvailability === 'On request' ? __('We will contact you about the shipping time') : null;

        const availabilityStatus = productAvailability === '24h' ? '24h' : `${productAvailability} ${__('days')}`;

        return (
            <div block="ProductActions" elem="PriceWrapper" mods={{ type: 'availability' }}>
                {contactText ? (
                    <span>{contactText}</span>
                ) : (
                    <>
                        {__('We will send in')}
                        <span>{availabilityStatus}</span>
                    </>
                )}
                <Icons name="info" fill="#10069F" />
            </div>
        );
    }

    renderAddToCartButton() {
        const { addToCart, inStock, quantity, getActiveProduct } = this.props;

        const product = getActiveProduct();

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock || Object.keys(product).length === 0}
                isIconEnabled={false}
                quantity={quantity}
                product={product}
                isShortButtonText
                customTexts={{
                    add: __('Add selected products to cart'),
                }}
            />
        );
    }

    renderAddToCartActionCmsBlock() {
        return <CmsBlock identifier="arrangement_add_to_cart" />;
    }

    renderAddToCartActionSection() {
        const {
            product,
            product: { name, show_as_set = false },
            quantity,
            getActiveProduct,
        } = this.props;

        if (show_as_set) {
            return null;
        }

        return (
            <Element name="ProductActionsAddToCartWrapper">
                <div block="ProductActions" elem="AddToCartWrapper" mods={{ isPrerendered: isSSR() || isCrawler() }}>
                    {this.renderAddToCartActionCmsBlock()}
                    <div>
                        <div block="ProductActions" elem="ContentWrapper">
                            <h2 block="ProductActions" elem="Heading">
                                {name}
                            </h2>
                            <ArrangementProductPrice
                                quantity={quantity}
                                product={product}
                                isSummaryPrice
                                getActiveProduct={getActiveProduct}
                            />
                            <div block="ProductActions" elem="ButtonsWrapper">
                                {this.renderAddToCartButton()}
                                {this.renderAvailability()}
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        );
    }

    renderArrangamentProductActions() {
        return (
            <>
                {this.renderGroupedOptions()}
                {this.renderAddToCartActionSection()}
            </>
        );
    }

    render() {
        const { setValidator } = this.props;

        return (
            <article block=" ProductActions" ref={(elem) => setValidator(elem)}>
                {this.renderArrangamentProductActions()}
            </article>
        );
    }
}

export default withRouter(ArrangementProductActions);
