import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from 'Type/ProductList.type';

import ArrangementProductPrice from './ArrangementProductPrice.component';

/** @namespace Pwa/Component/ArrangementProductPrice/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/ArrangementProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ArrangementProductPrice/Container/ArrangementProductPriceContainer */
export class ArrangementProductPriceContainer extends PureComponent {
    static propTypes = {
        isSummaryPrice: PropTypes.bool,
        product: ProductType.isRequired,
        quantity: PropTypes.objectOf(PropTypes.number).isRequired,
    };

    static defaultProps = {
        isSummaryPrice: false,
    };

    containerProps() {
        const { isSummaryPrice, product, quantity, isImageZoomPopupActive, getActiveProduct, activeProduct } =
            this.props;

        return {
            isSummaryPrice,
            product,
            quantity,
            isImageZoomPopupActive,
            activeProduct,
            getActiveProduct,
        };
    }

    render() {
        return <ArrangementProductPrice {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArrangementProductPriceContainer);
