import React, { Component } from 'react';

import AmastyBundleProducts from 'Component/AmastyBundleProducts';

import {
    DEFAULT_VISIBLE_ITEMS,
    FIRST_BUNDLE_INDEX,
    ITEMS_IN_ROW,
    ITEMS_VISIBLE_MOBILE,
    SECOND_BUNDLE_INDEX,
} from './AmastyBundleProductsList.config';

import './AmastyBundleProductsList.style';

/**
 * AmastyBundleProductsList description
 * @class AmastyBundleProductsList
 * @namespace Pwa/Component/AmastyBundleProductsList/Component/AmastyBundleProductsList */
export class AmastyBundleProductsList extends Component {
    state = {
        numberOfVisibleItems: DEFAULT_VISIBLE_ITEMS,
        showMoreBackgroundItemCount: 1,
        isExpanded: false,
    };

    renderProductList() {
        const { amastyBundleProducts, mainProduct, isMobile, isTablet } = this.props;
        const { numberOfVisibleItems, showMoreBackgroundItemCount, isExpanded } = this.state;
        const itemsSize = amastyBundleProducts?.length;

        if (!itemsSize) {
            return null;
        }

        const sliceArray =
            isMobile || isTablet ? numberOfVisibleItems : numberOfVisibleItems + showMoreBackgroundItemCount;
        const isMoreThanOneBundle = itemsSize > 1;
        const firstBundleLength = amastyBundleProducts[0]?.items?.length;
        const isExpandable =
            isMoreThanOneBundle ||
            firstBundleLength > ITEMS_IN_ROW ||
            ((isMobile || isTablet) && firstBundleLength > ITEMS_VISIBLE_MOBILE);

        return (
            <div>
                {amastyBundleProducts.slice(0, sliceArray).map((bundle, index) => (
                    <AmastyBundleProducts
                        bundle={bundle}
                        mainProduct={mainProduct}
                        isHiddenElement={
                            ((isMobile || isTablet) &&
                                index === FIRST_BUNDLE_INDEX &&
                                !isExpanded &&
                                bundle.items.length >= ITEMS_IN_ROW) ||
                            (index === FIRST_BUNDLE_INDEX && bundle.items.length > ITEMS_IN_ROW && !isExpanded) ||
                            (index === SECOND_BUNDLE_INDEX && !isExpanded)
                        }
                        isFirstHiddenElement={
                            index === FIRST_BUNDLE_INDEX && !isExpanded && bundle.items.length > ITEMS_IN_ROW
                        }
                    />
                ))}
                {isExpandable && !isExpanded ? this.renderShowMore() : null}
                {isExpandable && isExpanded ? this.renderShowLess() : null}
            </div>
        );
    }

    showMore() {
        const { amastyBundleProducts } = this.props;

        this.setState({ numberOfVisibleItems: amastyBundleProducts.length, isExpanded: true });
    }

    renderShowMore() {
        const { isMobile, isTablet, amastyBundleProducts } = this.props;
        const firstBundleLength = amastyBundleProducts[0]?.items?.length;
        const isFirstElementHidden = !isMobile && !isTablet && firstBundleLength > ITEMS_IN_ROW;

        return (
            <div block="AmastyBundleProductsList" elem="showMore" mods={{ isFirstElementHidden }}>
                <button block="AmastyBundleProductsList" elem="Button" onClick={this.showMore.bind(this)}>
                    {isMobile || isTablet ? __('See more') : __('Show more bundles')}
                </button>
            </div>
        );
    }

    showLess() {
        this.setState({ numberOfVisibleItems: DEFAULT_VISIBLE_ITEMS, isExpanded: false });
    }

    renderShowLess() {
        return (
            <div block="AmastyBundleProductsList" elem="showLess">
                <button block="AmastyBundleProductsList" elem="Button" onClick={this.showLess.bind(this)}>
                    {__('See less')}
                </button>
            </div>
        );
    }

    render() {
        return this.renderProductList();
    }
}

export default AmastyBundleProductsList;
