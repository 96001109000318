import { connect } from 'react-redux';

import { DataContainer } from 'Util/Request/DataContainer';

import AmastyRelatedProducts from './AmastyRelatedProducts.component';

/** @namespace Pwa/Component/AmastyRelatedProducts/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});
/** @namespace Pwa/Component/AmastyRelatedProducts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/AmastyRelatedProducts/Container/AmastyRelatedProductsContainer */
export class AmastyRelatedProductsContainer extends DataContainer {
    containerProps() {
        const { device, sectionData: { block_title = '', items = [] } = {} } = this.props;

        return {
            block_title,
            device,
            items,
        };
    }

    render() {
        return <AmastyRelatedProducts {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyRelatedProductsContainer);
