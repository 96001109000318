import { connect } from 'react-redux';

import { ConsentsContainer } from 'Component/Consents/Consents.container';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductReviewFormContainer as SourceProductReviewFormContainer,
} from 'SourceComponent/ProductReviewForm/ProductReviewForm.container';
import transformToNameValuePair from 'Util/Form/Transform';
import { getErrorMessage } from 'Util/Request';

/** @namespace Pwa/Component/ProductReviewForm/Container/ProductReviewFormContainer */
export class ProductReviewFormContainer extends SourceProductReviewFormContainer {
    async _onReviewSubmitSuccess(form, fields) {
        const { product, addReview, hideActiveOverlay, goToPreviousHeaderState, showNotification, reviewRatings } =
            this.props;

        const { ratingData: rating_data } = this.state;

        if (Object.keys(rating_data).length < Object.keys(reviewRatings).length) {
            showNotification('info', __('Please fill in all rating fields'));
            return;
        }

        this.setState({ isLoading: true });

        const accepted_consents = ConsentsContainer.getAcceptedConsents(fields);
        const { nickname, title, detail } = transformToNameValuePair(fields);

        const { sku: product_sku } = product;

        try {
            await addReview({
                nickname,
                title,
                detail,
                product_sku,
                rating_data,
                accepted_consents,
            });

            this.setState({
                ratingData: {},
                reviewData: {},
            });

            goToPreviousHeaderState();
            hideActiveOverlay();
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            this.setState({ isLoading: false });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewFormContainer);
