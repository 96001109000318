import React, { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Icons from 'Component/Icons/Icons.container';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderProgressBar from 'Component/SliderProgressBar';
import { ARRANGEMENTS_SLIDES_TO_SHOW, DEFAULT_ARRANGEMENTS_SLICK_SETTINGS } from 'Route/ProductPage/ProductPage.config';

import './RelatedBuiltProducts.style';

/** @namespace Pwa/Component/RelatedBuildProducts/RelatedBuiltProducts/Component/RelatedBuiltProducts */
export class RelatedBuiltProducts extends PureComponent {
    state = {
        currentSlide: 1,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    renderPaging() {
        const { data = [], isMobile } = this.props;
        const { currentSlide } = this.state;

        const pages = Math.ceil(data?.length / ARRANGEMENTS_SLIDES_TO_SHOW);
        const numberOfItems = data?.length;
        const showSlider = numberOfItems > ARRANGEMENTS_SLIDES_TO_SHOW;

        if (!isMobile || !showSlider) {
            return null;
        }

        return (
            <SliderCounterNav
                currentSlide={currentSlide}
                numberOfSlides={pages || 0}
                goToNextSlide={this.nextSlide}
                goToPrevSlide={this.previousSlide}
            />
        );
    }

    renderProgressBar() {
        const { data = [] } = this.props;
        const { currentSlide } = this.state;
        const pages = Math.ceil(data?.length / ARRANGEMENTS_SLIDES_TO_SHOW);
        const numberOfItems = data?.length;
        const showSlider = numberOfItems > ARRANGEMENTS_SLIDES_TO_SHOW;

        if (!showSlider) {
            return null;
        }

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={pages || 0} />;
    }

    renderItems() {
        const { data = [], settings } = this.props;
        const numberOfItems = data?.length;
        const showSlider = numberOfItems > ARRANGEMENTS_SLIDES_TO_SHOW;

        if (showSlider)
            return (
                <SlickSlider
                    prevArrow={<Icons name="arrow_left" fill="#10069F" />}
                    nextArrow={<Icons name="arrow_right" fill="#10069F" />}
                    {...DEFAULT_ARRANGEMENTS_SLICK_SETTINGS}
                    {...settings}
                    beforeChange={(_, nextSlide) => {
                        this.setState({ currentSlide: nextSlide / ARRANGEMENTS_SLIDES_TO_SHOW + 1 });
                    }}
                    // eslint-disable-next-line no-return-assign
                    ref={(slider) => (this.slider = slider)}
                >
                    {this.renderArrangementsItems()}
                </SlickSlider>
            );
        if (!showSlider) {
            return (
                <div block="RelatedBuiltProducts" elem="Box">
                    {this.renderArrangementsItems()}
                </div>
            );
        }
    }

    renderArrangementsItems() {
        const { data = [] } = this.props;

        return data?.map((product) => (
            <ProductCard
                product={product}
                hideVariants
                hiddenDeliveryTime
                hiddenHoverSection
                hiddenActions
                isArrangement
                hiddenOmnibus
                isNotListElement
                style={{ height: '250px' }}
            />
        ));
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="RelatedBuiltProducts" elem="Wrapper">
                <h2>{__('Bathrooms with this product')}</h2>
                <Loader isLoading={isLoading} />
                {this.renderPaging()}
                {this.renderItems()}
                {this.renderProgressBar()}
            </div>
        );
    }
}

export default RelatedBuiltProducts;
