import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CartPriceRules/Query/CartPriceRulesQuery */
export class CartPriceRulesQuery {
    getCartPriceRules(productId) {
        const query = new Field('pdpRules')
            .addArgument('productId', 'Int', productId)
            .addFieldList(this.getCartPriceRuleFields());

        return query;
    }

    getCartPriceRuleFields() {
        return ['coupon_code', 'description', 'rule_name', 'type'];
    }
}

export default new CartPriceRulesQuery();
