import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

/** @namespace Pwa/Component/Shoppers/Component/Shoppers */
export class Shoppers extends PureComponent {
    renderBasicScript() {
        const { shopId = '' } = this.props;

        const basicScriptContent = `"use strict";(function(scriptUrl,campaignId){if(!window.bb){window.bb=function(){
                for(var _len=arguments.length,params=Array(_len),_key=0;_key<_len;_key++){
                params[_key]=arguments[_key]}return window.bb.q.push(params)};window.bb.q=[]
                ;window.bb.campaignId=campaignId;var script=document.createElement("script")
                ;var firstScript=document.getElementsByTagName("script")[0];script.async=true
                ;script.src=scriptUrl;firstScript.parentNode.insertBefore(script,firstScript)}
                })("https://shop-js.buybox.click/js/bb-shop.min.js", ${shopId},0);`;

        return (
            <script defer type="text/javascript">
                {basicScriptContent}
            </script>
        );
    }

    renderProductScript() {
        const { isProductPage, productID } = this.props;

        if (!isProductPage) {
            return null;
        }

        if (!window.bb) {
            return null;
        }

        const productScriptContent = `bb("productView", { "productId": "${productID}" })`;

        return (
            <script defer type="text/javascript">
                {productScriptContent}
            </script>
        );
    }

    renderOrderScript() {
        const {
            isSuccessPage,
            isPaymentPage,
            commissionId = '',
            configData: { configData: { products = [] } = {} } = {},
        } = this.props;

        if (!isSuccessPage && !isPaymentPage) {
            return null;
        }

        const orderID = sessionStorage?.getItem('ORDER_ID');
        const items = products?.map((product) => ({
            productId: product?.id,
            quantity: product?.qty,
            commissionId,
            gross: product?.price,
        }));
        const orderScriptContent = `
        bb("order", { "orderId": ${orderID}, "products": ${JSON.stringify(items)}})
        `;

        return (
            <script defer type="text/javascript">
                {orderScriptContent}
            </script>
        );
    }

    render() {
        return (
            <Helmet>
                {this.renderBasicScript()}
                {this.renderProductScript()}
                {this.renderOrderScript()}
            </Helmet>
        );
    }
}

export default Shoppers;
