export const SHOW_SOCIAL_PROOF = 'SHOW_SOCIAL_PROOF';
export const HIDE_SOCIAL_PROOF = 'HIDE_SOCIAL_PROOF';
export const CLEAR_SOCIAL_PROOF_LIST = 'CLEAR_SOCIAL_PROOF_LIST';

/** @namespace Pwa/Store/SocialProof/Action/showSocialProof */
export const showSocialProof = (msgType, msgText, msgDebug) => ({
    type: SHOW_SOCIAL_PROOF,
    msgType,
    msgText,
    msgDebug,
});

/** @namespace Pwa/Store/SocialProof/Action/hideSocialProof */
export const hideSocialProof = (id) => ({
    type: HIDE_SOCIAL_PROOF,
    id,
});

/** @namespace Pwa/Store/SocialProof/Action/clearSocialProofList */
export const clearSocialProofList = () => ({
    type: CLEAR_SOCIAL_PROOF_LIST,
});
