import { lazy, Suspense } from 'react';

import ArrangementProductActions from 'Component/ArrangementProductActions';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader/Loader.component';
import ProductDetails from 'Component/ProductDetails';
import ProductHeader from 'Component/ProductHeader';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatchHandler from 'Route/NoMatchHandler';
import { ProductPage as SourceProductPage, ProductReviews } from 'Route/ProductPage/ProductPage.component';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';

import './ArrangementProduct.style';

export const ArrangementProductGallery = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
        'Component/ArrangementProductGallery'
    )
);
/** @namespace Pwa/Route/ArrangementProduct/Component/ArrangementProductPage */
export class ArrangementProductPage extends SourceProductPage {
    tabMap = {};

    renderDescription() {
        const {
            activeProduct: { description: { html } = {} },
            areDetailsLoaded,
        } = this.props;

        if (!html && !areDetailsLoaded) {
            return <TextPlaceholder length="long" />;
        }

        const cleanDescription = html.replace(/<\/?[^>]+(>|$)/g, '');

        return (
            <div block="ProductInformation" elem="Description">
                <meta itemProp="description" content={cleanDescription} />
                <h3 block="ProductInformation" elem="Header">
                    {__('Description')}
                </h3>
                <Html content={html} />
            </div>
        );
    }

    renderAdvantages() {
        const {
            activeProduct: { advantages = null },
        } = this.props;

        return (
            <div block="ProductInformation" elem="Advantages">
                <h3 block="ProductInformation" elem="Header">
                    {__('Biggest advantages')}
                </h3>
                <div block="ProductInformation" elem="List">
                    {advantages && <Html content={advantages} />}
                </div>
            </div>
        );
    }

    renderDesignerTitle() {
        return (
            <h3 block="ProductInformation" elem="Header">
                {__('Designer')}
            </h3>
        );
    }

    renderDesigner() {
        const { isMobile, activeProduct } = this.props;
        const { designer, categories } = activeProduct || {};
        const { first_name, last_name, image, description } = designer || {};
        const { url: categoryUrl } = BreadcrumbsDispatcher.findLongestBreadcrumbs(categories) || {};
        const to = `${categoryUrl}?customFilters=pdp_designer:${encodeURIComponent(
            [first_name, last_name].filter(Boolean).join(' ')
        )}`;

        if (!designer) {
            return null;
        }

        return (
            <div block="ProductInformation" elem="Designer">
                {isMobile && this.renderDesignerTitle()}
                <div block="ProductInformation" elem="DesignerInfo">
                    <Image
                        src={image}
                        alt={[first_name, last_name].filter(Boolean).join(' ')}
                        mix={{ block: 'ProductInformation', elem: 'DesignerImage' }}
                        ratio="custom"
                    />
                    <div block="ProductInformation" elem="DesignerContent">
                        {!isMobile && this.renderDesignerTitle()}
                        <span>{[first_name, last_name].filter(Boolean).join(' ')}</span>
                        {description ? <p>{description}</p> : null}
                    </div>
                </div>
                <Link to={to}>
                    {__('See more arrangements by')} {[first_name, last_name].filter(Boolean).join(' ')}
                </Link>
            </div>
        );
    }

    renderProductInformation() {
        const { areDetailsLoaded } = this.props;
        const heading = areDetailsLoaded ? __('About this bathroom') : '';

        return (
            <div heading={heading} mix={{ block: 'ProductInformation', elem: 'Container' }}>
                <h2 block="ProductInformation" elem="Header">
                    {heading}
                </h2>
                <div block="ProductInformation" elem="Content">
                    {this.renderDescription()}
                    {this.renderAdvantages()}
                    {this.renderDesigner()}
                </div>
            </div>
        );
    }

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
            quantity,
            magentoProduct,
        } = this.props;

        return (
            <>
                <ProductHeader
                    getLink={getLink}
                    product={dataSource}
                    parameters={parameters}
                    areDetailsLoaded={areDetailsLoaded}
                    setActiveProduct={setActiveProduct}
                    showCompareButton={false}
                />
                <Suspense fallback={<Loader />}>
                    <ArrangementProductGallery
                        product={activeProduct}
                        areDetailsLoaded={areDetailsLoaded}
                        isWithEmptySwitcher={useEmptyGallerySwitcher}
                        showLoader={isVariant}
                        getLink={getLink}
                        dataSource={dataSource}
                        parameters={parameters}
                        setActiveProduct={setActiveProduct}
                        quantity={quantity}
                        magentoProduct={magentoProduct}
                    />
                </Suspense>
            </>
        );
    }

    renderProductDetails(areDetailsLoaded) {
        return (
            <ProductDetails sections={this.tabMap} areDetailsLoaded={areDetailsLoaded} isArrangementProductDetails />
        );
    }

    render() {
        const { getLink, dataSource, areDetailsLoaded, setActiveProduct, parameters, setQuantity } = this.props;

        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                    mods={{ ArrangementPage: true }}
                >
                    <ContentWrapper
                        wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}
                    </ContentWrapper>
                    {this.renderProductInformation()}
                    <ArrangementProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                        setArrangamentProductQty={setQuantity}
                    />
                    {this.renderAdditionalSections()}
                    <ProductReviews product={dataSource} areDetailsLoaded={areDetailsLoaded} />
                    {this.renderReviewPopup()}
                </main>
            </NoMatchHandler>
        );
    }
}

export default ArrangementProductPage;
