import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps } from 'Component/Product/Product.container';
import { ProductActionsContainer as SourceProductActionsContainer } from 'Component/ProductActions/ProductActions.container';

import ArrangementProductActions from './ArrangementProductActions.component';

/** @namespace Pwa/Component/ArrangementProductActions/Container/ArrangamentProductActionsContainer */
export class ArrangamentProductActionsContainer extends SourceProductActionsContainer {
    static propTypes = {
        ...SourceProductActionsContainer.propTypes,
        setArrangamentProductQty: PropTypes.func.isRequired,
    };

    setQuantity(quantity) {
        const { setArrangamentProductQty } = this.props;

        if (typeof quantity === 'object') {
            const { quantity: oldQuantity = {} } = this.state;
            this.setState({ quantity: { ...oldQuantity, ...quantity } });
            setArrangamentProductQty({ ...oldQuantity, ...quantity });
        } else {
            this.setState({ quantity: +quantity });
        }
    }

    render() {
        return <ArrangementProductActions {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArrangamentProductActionsContainer);
