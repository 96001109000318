import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'Component/ProductCard/ProductCard.container';

import AmastyBundleProduct from './AmastyBundleProduct.component';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/AmastyBundleProduct/Container/AmastyBundleProductContainer */
export class AmastyBundleProductContainer extends SourceProductCardContainer {
    state = {
        ...this.state,
        isChecked: true,
    };

    containerFunctions = {
        ...this.containerFunctions,
        setBundleItem: this.setBundleItem.bind(this),
    };

    setBundleItem() {
        const { isChecked } = this.state;
        const {
            product: { id },
            onCheckboxClick,
        } = this.props;

        this.setState({ isChecked: !isChecked });
        onCheckboxClick(id);
    }

    containerProps() {
        const { isBundle, discountValue, discountType, bundleDiscountDisabled, isLink } = this.props;
        const { isChecked } = this.state;

        return {
            ...super.containerProps(),
            discountValue,
            discountType,
            isBundle,
            isChecked,
            bundleDiscountDisabled,
            isLink,
        };
    }

    render() {
        return <AmastyBundleProduct {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyBundleProductContainer);
