import { Field } from 'Util/Query';

/** @namespace Pwa/Query/AskAboutProduct/Query/AskAboutProduct */
export class AskAboutProduct {
    askAboutProductMutation(input) {
        const mutation = new Field('askAboutProduct')
            .addArgument('input', 'AskAboutProductInput ', input)
            .addFieldList(['success', 'message']);

        return mutation;
    }
}

export default new AskAboutProduct();
