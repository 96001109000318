import Consents from 'Component/Consents';
import { CONSENTS_RATING } from 'Component/Consents/Consents.config';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { ProductReviewForm as SourceProductReviewForm } from 'SourceComponent/ProductReviewForm/ProductReviewForm.component';

import './ProductReviewForm.override.style';

/** @namespace Pwa/Component/ProductReviewForm/Component/ProductReviewForm */
export class ProductReviewForm extends SourceProductReviewForm {
    renderReviewFormContent() {
        const { reviewData } = this.props;

        const { nickname = '', detail = '' } = reviewData;

        return (
            <div block="ProductReviewForm" elem="Wrapper">
                <div>{this.renderReviewRating()}</div>
                <div block="ProductReviewForm" elem="Content">
                    <Field
                        type={FIELD_TYPE.text}
                        label={__('Nickname')}
                        attr={{
                            id: 'nickname',
                            name: 'nickname',
                            defaultValue: nickname,
                            placeholder: __('Your nickname'),
                        }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                        }}
                    />
                    <Field
                        type={FIELD_TYPE.text}
                        label={__('Title')}
                        attr={{
                            id: 'title',
                            name: 'title',
                            defaultValue: __('No title'),
                            placeholder: __('Title'),
                        }}
                        mix={{ block: 'ProductReviewForm', elem: 'Title' }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                        }}
                    />
                    <Field
                        type={FIELD_TYPE.textarea}
                        label={__('Review')}
                        attr={{
                            id: 'detail',
                            name: 'detail',
                            defaultValue: detail,
                            placeholder: __('Review'),
                        }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                        }}
                        addRequiredTag
                    />
                    <Consents type={CONSENTS_RATING} />
                </div>
            </div>
        );
    }
}

export default ProductReviewForm;
