import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Shoppers from './Shoppers.component';

/** @namespace Pwa/Component/Shoppers/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    shopId: state.ConfigReducer.system100shoppers_shop_id,
    commissionId: state.ConfigReducer.system100shoppers_commission_id,
});

/** @namespace Pwa/Component/Shoppers/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/Shoppers/Container/ShoppersContainer */
export class ShoppersContainer extends PureComponent {
    containerProps() {
        const { isProductPage, productID, isSuccessPage, configData, shopId, commissionId, isPaymentPage } = this.props;

        return {
            configData,
            isProductPage,
            isSuccessPage,
            productID,
            shopId,
            commissionId,
            isPaymentPage,
        };
    }

    render() {
        return <Shoppers {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppersContainer);
