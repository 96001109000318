import React from 'react';
import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import ProductPageBlogPosts from './ProductPageBlogPosts.component';

/** @namespace Pwa/Component/ProductPageBlogPosts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductPageBlogPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductPageBlogPosts/Container/ProductPageBlogPostsContainer */
export class ProductPageBlogPostsContainer extends DataContainer {
    containerProps() {
        const { device, posts } = this.props;

        return { device, posts };
    }

    render() {
        return <ProductPageBlogPosts {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageBlogPostsContainer);
