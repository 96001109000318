export { UPDATE_PRODUCT_DETAILS, updateProductDetails } from 'SourceStore/Product/Product.action';

export const APPEND_PRODUCT_DETAILS = 'APPEND_PRODUCT_DETAILS';

/**
 * Append product with additional details (doesn't override existing).
 * @param  {Object} product New product details to be added
 * @return {void}
 * @namespace Pwa/Store/Product/Action/appendProductDetails */
export const appendProductDetails = (product) => ({
    type: APPEND_PRODUCT_DETAILS,
    product,
});
