import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { SIMPLE_PRODUCT } from 'Route/ProductPage/ProductPage.config';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductDescription/Component/ProductDescription */
export class ProductDescription extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
    };

    renderDescriptionDesktopBanner() {
        const {
            product: { type_id },
            isMobile,
        } = this.props;

        if (isMobile || type_id !== SIMPLE_PRODUCT) {
            return null;
        }

        return <CmsBlock identifier="pdp_banner2_desktop" blockType="pdpBannerDesktopSecond" />;
    }

    renderDescriptionMobileBanner() {
        const {
            product: { type_id },
            isMobile,
        } = this.props;

        if (!isMobile || type_id !== SIMPLE_PRODUCT) {
            return null;
        }

        return <CmsBlock identifier="pdp_banner2_mobile" blockType="pdpBannerMobileSecond" />;
    }

    renderDescription() {
        const {
            product: { description: { html } = {} },
            areDetailsLoaded,
        } = this.props;

        if (!html && !areDetailsLoaded) {
            return <TextPlaceholder length="long" />;
        }

        const cleanDescription = html.replace(/<\/?[^>]+(>|$)/g, '');

        return (
            <div block="ProductDescription" elem="Description">
                <meta itemProp="description" content={cleanDescription} />
                <Html content={html} noImgAttributesTransform />
            </div>
        );
    }

    renderContent() {
        return (
            <>
                {this.renderDescriptionDesktopBanner()}
                {this.renderDescriptionMobileBanner()}
                {this.renderDescription()}
            </>
        );
    }

    render() {
        const { areDetailsLoaded } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
                mix={{ block: 'ProductDescription' }}
                wrapperMix={{ block: 'ProductDescription', elem: 'Wrapper' }}
            >
                {this.renderContent()}
            </ContentWrapper>
        );
    }
}

export default ProductDescription;
