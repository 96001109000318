import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer,
} from 'Component/Product/Product.container';
import { getName, getProductUrlName } from 'Util/Product/Extract';

import ProductHeader from './ProductHeader.component';

/** @namespace Pwa/Component/ProductHeader/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductHeader/Container/ProductHeaderContainer */
export class ProductHeaderContainer extends ProductContainer {
    static propTypes = {
        ...ProductContainer.propTypes,
        showCompareButton: PropTypes.bool,
    };

    static defaultProps = {
        ...ProductContainer.defaultProps,
        showCompareButton: true,
    };

    containerProps() {
        const { device, showCompareButton } = this.props;

        return {
            ...super.containerProps(),
            productName: this.getName(),
            device,
            showCompareButton,
        };
    }

    getName() {
        const { areDetailsLoaded, product } = this.props;

        if (!areDetailsLoaded) {
            return getProductUrlName();
        }

        return getName(product);
    }

    render() {
        return <ProductHeader {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductHeaderContainer);
