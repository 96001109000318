import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ConsentsContainer } from 'Component/Consents/Consents.container';
import AskAboutProductQuery from 'Query/AskAboutProduct.query';
import { showNotification } from 'Store/Notification/Notification.action';
import fromCache from 'Util/Cache/Cache';
import { getName, getPrice } from 'Util/Product/Extract';
import { fetchMutation } from 'Util/Request';

import AskAboutProduct from './AskAboutProduct.component';

/** @namespace Pwa/Component/AskAboutProduct/Container/mapStateToProps */
export const mapStateToProps = () => {};

/** @namespace Pwa/Component/AskAboutProduct/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error)),
});

/** @namespace Pwa/Component/AskAboutProduct/Container/AskAboutProductContainer */
export class AskAboutProductContainer extends PureComponent {
    containerFunctions = {
        onFormSubmit: this.onFormSubmit.bind(this),
    };

    state = {
        isLoading: false,
    };

    async onFormSubmit(form, fields) {
        const { showNotification, product } = this.props;
        const filteredFields = {};

        const consents = ConsentsContainer.getAllConsents(fields);

        fields.forEach(({ name, value }) => {
            if (consents?.includes(name)) {
                return null;
            }
            filteredFields[name] = value;
        });

        const input = {
            ...filteredFields,
            product_id: `${product.id}`,
            product_price: `${product?.price_range?.minimum_price?.final_price?.value}`,
        };

        this.setState({ isLoading: true });

        await fetchMutation(AskAboutProductQuery.askAboutProductMutation(input))
            .then(
                /** @namespace Pwa/Component/AskAboutProduct/Container/fetchMutation/then */
                ({ askAboutProduct: { success } }) => {
                    if (success) {
                        showNotification('success', __('Message sent'));
                    } else {
                        showNotification('error', __('Error occured'));
                    }
                    this.setState({ isLoading: false });
                },
                /** @namespace Pwa/Component/AskAboutProduct/Container/fetchMutation/then */
                () => {
                    showNotification('error', __('Error occured'));
                    this.setState({ isLoading: false });
                }
            )
            .finally(
                /** @namespace Pwa/Component/AskAboutProduct/Container/AskAboutProductContainer/onFormSubmit/then/finally */
                () => {
                    if (typeof form.reset === 'function') {
                        form.reset();
                    }
                }
            );
    }

    containerProps() {
        const { isLoading } = this.state;
        const { product } = this.props;
        const { price_range: priceRange = {}, dynamic_price: dynamicPrice = false, type_id: type } = product || {};

        const productName = getName(product);
        const productPrice = fromCache(getPrice, [priceRange, dynamicPrice, {}, type, []]);

        return { isLoading, product, productName, productPrice };
    }

    render() {
        return <AskAboutProduct {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AskAboutProductContainer);
