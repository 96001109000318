import {
    BUNDLE_DISCOUNT_TYPE_FIXED,
    BUNDLE_DISCOUNT_TYPE_PERCENTAGE,
} from 'Component/AmastyBundleProducts/AmastyBundleProducts.config';
import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductCard as SourceProductCard } from 'Component/ProductCard/ProductCard.component';
import ProductDiscountLabel from 'Component/ProductDiscountLabel';
import ProductStockAvailability from 'Component/ProductStockAvailability';
import { formatPrice } from 'Util/Price';

/** @namespace Pwa/Component/AmastyBundleProduct/Component/AmastyBundleProduct */
export class AmastyBundleProduct extends SourceProductCard {
    renderCheckbox() {
        const { product: { id } = {}, setBundleItem, isChecked = true, isBundle } = this.props;

        if (!isBundle) {
            return null;
        }

        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    name: `bundle_item_${id}`,
                    checked: isChecked,
                }}
                events={{
                    onChange: setBundleItem,
                }}
                mix={{ block: 'ProductCard', elem: 'Checkbox' }}
            />
        );
    }

    renderDeliveryTime() {
        const {
            hiddenDeliveryTime,
            product: { stock_availability, deliverer_available },
        } = this.props;

        if (hiddenDeliveryTime || !stock_availability?.qty) {
            return <div block="ProductCard" elem="DeliveryTime" mods={{ empty: true }} />;
        }

        return (
            <div block="ProductCard" elem="DeliveryTime">
                <ProductStockAvailability
                    stock_availability={stock_availability}
                    deliverer_available={deliverer_available}
                />
            </div>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="ContentWrapper">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                        {this.renderDiscount()}
                        {this.renderProductActions()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content">
                    {this.renderBundlePrice()}
                    {this.renderProductName()}
                    {this.renderDeliveryTime()}
                </div>
                {this.renderVisibleOnHover()}
            </div>
        );
    }

    renderCardContentWrapper() {
        const {
            isLink,
            product: { url },
        } = this.props;

        if (isLink) {
            return <Link to={url}>{this.renderCardContent()}</Link>;
        }

        return this.renderCardContent();
    }

    render() {
        const { mix, isLoading, layout } = this.props;

        return (
            <li block="ProductCard" mods={{ layout }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderCheckbox()}
                {this.renderCardContentWrapper()}
            </li>
        );
    }

    calculateDiscountLabelValue() {
        const {
            product: {
                price_range: {
                    maximum_price: { regular_price = {}, final_price = {}, discount: { amount_off } } = {},
                } = {},
            } = {},
        } = this.props;

        const baseDiscountAvailable = amount_off > 0;
        const specialPriceValue = baseDiscountAvailable
            ? this.calculateSpecialPrice(final_price?.value)
            : this.calculateSpecialPrice(regular_price?.value);

        const calculatedDiscountLabelValue = Math.round(
            ((specialPriceValue - regular_price?.value) / regular_price?.value) * 100
        );

        if (calculatedDiscountLabelValue === 0) {
            return null;
        }

        return calculatedDiscountLabelValue;
    }

    calculateSpecialPrice(value) {
        const { discountType, discountValue, isChecked, bundleDiscountDisabled } = this.props;

        if (!isChecked || bundleDiscountDisabled) {
            return value;
        }
        if (discountType === BUNDLE_DISCOUNT_TYPE_FIXED) {
            const specialPriceValue = value - discountValue;

            return specialPriceValue;
        }

        if (discountType === BUNDLE_DISCOUNT_TYPE_PERCENTAGE) {
            const specialPriceValue = (value - value * (discountValue / 100)).toFixed(2);
            return +specialPriceValue;
        }
    }

    renderBundlePrice() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        discount: { amount_off },
                    },
                },
            } = {},
            productPrice,
        } = this.props;

        const baseDiscountAvailable = amount_off > 0;

        if (!productPrice) {
            return null;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                    <ins block="ProductPrice" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            {this.renderSpecialBundlePrice()}
                        </span>
                    </ins>

                    <del block="ProductPrice" elem="HighPrice">
                        {baseDiscountAvailable ? this.renderRegularBundlePrice() : this.renderFinalBundlePrice()}
                    </del>
                </div>
            </div>
        );
    }

    renderSpecialBundlePrice() {
        const {
            product: {
                price_range: {
                    maximum_price: { regular_price = {}, final_price = {}, discount: { amount_off } } = {},
                } = {},
            } = {},
        } = this.props;
        const baseDiscountAvailable = amount_off > 0;

        if (!regular_price?.value && !final_price?.value) {
            return null;
        }

        const specialPriceValue = baseDiscountAvailable
            ? this.calculateSpecialPrice(final_price?.value)
            : this.calculateSpecialPrice(regular_price?.value);

        return <>{formatPrice(specialPriceValue, regular_price?.currency)}</>;
    }

    renderRegularBundlePrice() {
        const { product: { price_range: { maximum_price: { regular_price } = {} } = {} } = {} } = this.props;

        if (!regular_price?.value) {
            return null;
        }

        return <>{formatPrice(regular_price?.value, regular_price?.currency)}</>;
    }

    renderFinalBundlePrice() {
        const { product: { price_range: { minimum_price: { final_price = {} } = {} } = {} } = {} } = this.props;

        if (!final_price?.value) {
            return null;
        }

        return <>{formatPrice(final_price?.value, final_price?.currency)}</>;
    }

    renderDiscount() {
        const percentageDiscount = this.calculateDiscountLabelValue();

        return (
            <div block="ProductCard" elem="Discount">
                <ProductDiscountLabel percentageDiscount={percentageDiscount} />
            </div>
        );
    }
}

export default AmastyBundleProduct;
