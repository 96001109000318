/* eslint-disable indent */
/* eslint-disable array-callback-return */
import AddIcon from 'Component/AddIcon';
import Link from 'Component/Link';
import MinusIcon from 'Component/MinusIcon';
import { Product } from 'Component/Product/Product.component';
import { isNumeric } from 'Util/Number';
import { getRelativeURL } from 'Util/Url/Url';

import {
    DEFAULT_VARIANT_LABEL,
    EXPECTED_VARIANT_PIXELS_HEIGHT,
    MAIN_VARIANT_INDEX,
    SECONDARY_VARIANT_INDEX,
    TERTIARY_VARIANT_INDEX,
    VARIANT_IMAGE_PATH,
    VARIANT_SWATCH_SIZE,
} from './ProductVariants.config';

import './ProductVariants.style';

/** @namespace Pwa/Component/ProductVariants/Component/ProductVariants  */
export class ProductVariants extends Product {
    componentDidMount() {
        this.toggleClickListener();
    }

    componentDidUpdate() {
        const variantElems = document?.querySelectorAll('.ProductVariants-Options');

        if (variantElems?.length) {
            Array.from(variantElems, (variant) => {
                if (variant.clientHeight > EXPECTED_VARIANT_PIXELS_HEIGHT) {
                    variant.classList.add('expanded');
                }
            });
        }
    }

    componentWillUnmount() {
        this.toggleClickListener('remove');
    }

    toggleClickListener(type = 'add') {
        if (type === 'remove') {
            document.removeEventListener('click', this.handleToggleVariantsClick.bind(this));
            return;
        }

        document.addEventListener('click', this.handleToggleVariantsClick.bind(this));
    }

    handleToggleVariantsClick(e) {
        const { target } = e;

        if (target?.classList.contains('ProductVariants-ToggleButton')) {
            target.previousSibling.classList.toggle('isActive');
        }
    }

    getActiveVariants(group, dataContainer, productID) {
        group?.map(({ variants }) => {
            Object.entries(variants)?.map((variant) => {
                if (variant[1].product.id === productID) {
                    Object.entries(variant[1].attributes)?.map((elem) => {
                        dataContainer[elem[1].attribute_code] = elem[1].label;
                    });
                }
            });
        });
    }

    getProductVariants() {
        const {
            product: { group },
        } = this.props;

        return group?.[0]?.variants;
    }

    getUniqueMainVariants(arr, key) {
        if (key === DEFAULT_VARIANT_LABEL) {
            return Array.from(new Map(arr.map((item) => [item.attributes[0][key], item])).values());
        }
        const uniqueVariants = [];
        arr.map((item) => {
            if (item.attributes[1].label === key) {
                uniqueVariants.push(item);
            }
        });

        return uniqueVariants;
    }

    sortVariants(variants) {
        // NOTE: only works for one numeric attribute in variants. In case of two (or more) numeric
        // attributes (e.g. height, width) it will only take first into account

        if (!variants) {
            return variants;
        }

        const sortableAttributes = {};

        variants.forEach((variant) => {
            variant.attributes?.forEach((attr) => {
                if (isNumeric(attr.label)) {
                    sortableAttributes[attr.attribute_code] = true;
                }
            });
        });

        if (Object.keys(sortableAttributes).length === 0) {
            return variants;
        }

        const [sortableAttrCode] = Object.keys(sortableAttributes);

        variants = variants.sort(({ attributes: AAtributes }, { attributes: BAttributes }) => {
            const aLabel = AAtributes.find((attr) => attr.attribute_code === sortableAttrCode).label;
            const bLabel = BAttributes.find((attr) => attr.attribute_code === sortableAttrCode).label;

            return aLabel - bLabel;
        });

        return variants;
    }

    renderToggleVariantsButton() {
        return (
            <button type="button" block="ProductVariants" elem="ToggleButton">
                <AddIcon />
                <MinusIcon />
            </button>
        );
    }

    renderVariant(url, label, isActive, isSwatch, color) {
        const imageRegex = new RegExp(/\.(jpg|jpeg|png|webp|gif|svg)$/);
        const isImageSwatch = imageRegex.test(color);
        const swatchUrl = `${VARIANT_IMAGE_PATH + color + VARIANT_SWATCH_SIZE}`;

        if (!isActive) {
            return (
                <Link
                    to={url}
                    title={label}
                    block="ProductVariants"
                    elem="Option"
                    mods={{
                        isActive,
                        isSwatch,
                    }}
                    style={{ background: color || 'transparent' }}
                >
                    {!isSwatch && label}
                    {isImageSwatch && <img alt={label} src={swatchUrl} />}
                </Link>
            );
        }

        return (
            <div
                block="ProductVariants"
                elem="Option"
                mods={{
                    isActive,
                    isSwatch,
                }}
                style={{ background: color || 'transparent' }}
            >
                {!isSwatch && label}
                {isImageSwatch && <img alt={label} src={swatchUrl} />}
            </div>
        );
    }

    renderMainVariants() {
        const {
            product: { group, id, attributes },
        } = this.props;

        if (!group?.length) {
            return null;
        }

        const { variants } = group[0];
        const MAIN_VARIANT_CODE = variants[0]?.attributes[MAIN_VARIANT_INDEX].attribute_code;
        const SECONDARY_VARIANT_CODE = variants[0]?.attributes[1]?.attribute_code;
        const TERTIARY_VARIANT_CODE = variants[0]?.attributes[2]?.attribute_code;

        const activeVariants = {};
        this.getActiveVariants(group, activeVariants, id);
        const variantLabel =
            Object.keys(activeVariants).length > 1
                ? activeVariants[Object.keys(activeVariants)[1]]
                : DEFAULT_VARIANT_LABEL;
        const filteredVariants = this.getUniqueMainVariants(this.sortVariants(variants), variantLabel);

        return (
            <div block="ProductVariants" elem="SubWrapper">
                {group?.map(({ variants }) => (
                    <>
                        <div block="ProductVariants" elem="OptionTitle">
                            {variants[1]?.attributes[0].attribute_label}
                        </div>
                        <div block="ProductVariants" elem="Options">
                            {filteredVariants?.map(
                                (variant) =>
                                    attributes?.[SECONDARY_VARIANT_CODE]?.attribute_value ===
                                        variant?.attributes[1]?.label &&
                                    attributes?.[TERTIARY_VARIANT_CODE]?.attribute_value ===
                                        variant?.attributes[2]?.label &&
                                    variant.attributes?.map(
                                        (elem) =>
                                            elem.attribute_code === MAIN_VARIANT_CODE &&
                                            this.renderVariant(
                                                getRelativeURL(variant.product.url),
                                                elem.label,
                                                activeVariants[elem.attribute_code] === elem.label,
                                                elem.is_swatch,
                                                elem.swatch_value
                                            )
                                    )
                            )}
                        </div>
                        {this.renderToggleVariantsButton()}
                    </>
                ))}
            </div>
        );
    }

    renderSecondaryVariants() {
        const {
            product: { group, id, attributes },
        } = this.props;

        if (!group?.length) {
            return null;
        }
        const { variants } = group[0];
        const activeVariants = {};
        this.getActiveVariants(group, activeVariants, id);

        const MAIN_VARIANT_CODE = variants[0]?.attributes[MAIN_VARIANT_INDEX]?.attribute_code;
        const TERTIARY_VARIANT_CODE = variants[0]?.attributes[2]?.attribute_code;
        const MAIN_VARIANT_ACTIVE_VALUE = activeVariants[MAIN_VARIANT_CODE];

        return variants[1]?.attributes.map(
            (attribute, index) =>
                index === SECONDARY_VARIANT_INDEX && (
                    <div block="ProductVariants" elem="SubWrapper">
                        <div block="ProductVariants" elem="OptionTitle">
                            {attribute.attribute_label}
                        </div>
                        <div block="ProductVariants" elem="Options">
                            {Object.entries(this.sortVariants(variants))?.map(
                                (variant) =>
                                    variant[1]?.attributes[0].label === MAIN_VARIANT_ACTIVE_VALUE &&
                                    attributes?.[TERTIARY_VARIANT_CODE]?.attribute_value ===
                                        variant[1]?.attributes[2]?.label &&
                                    this.renderVariant(
                                        getRelativeURL(variant[1].product.url),
                                        variant[1].attributes[index].label,
                                        activeVariants[variant[1].attributes[index].attribute_code] ===
                                            variant[1].attributes[index].label,
                                        variant[1].attributes[index].is_swatch
                                    )
                            )}
                        </div>
                        {this.renderToggleVariantsButton()}
                    </div>
                )
        );
    }

    renderTertiaryVariants() {
        const {
            product: { group, id, attributes },
        } = this.props;

        if (!group?.length) {
            return null;
        }
        const { variants } = group[0];
        const activeVariants = {};
        this.getActiveVariants(group, activeVariants, id);

        const MAIN_VARIANT_CODE = variants[0]?.attributes[MAIN_VARIANT_INDEX]?.attribute_code;
        const SECONDARY_VARIANT_CODE = variants[0]?.attributes[1]?.attribute_code;
        const MAIN_VARIANT_ACTIVE_VALUE = activeVariants[MAIN_VARIANT_CODE];

        return variants[1]?.attributes.map(
            (attribute, index) =>
                index === TERTIARY_VARIANT_INDEX && (
                    <div block="ProductVariants" elem="SubWrapper">
                        <div block="ProductVariants" elem="OptionTitle">
                            {attribute.attribute_label}
                        </div>
                        <div block="ProductVariants" elem="Options">
                            {Object.entries(this.sortVariants(variants))?.map(
                                (variant) =>
                                    variant[1]?.attributes[0].label === MAIN_VARIANT_ACTIVE_VALUE &&
                                    attributes?.[SECONDARY_VARIANT_CODE]?.attribute_value ===
                                        variant[1]?.attributes[1]?.label &&
                                    this.renderVariant(
                                        getRelativeURL(variant[1].product.url),
                                        variant[1].attributes[index].label,
                                        activeVariants[variant[1].attributes[index].attribute_code] ===
                                            variant[1].attributes[index].label,
                                        variant[1].attributes[index].is_swatch
                                    )
                            )}
                        </div>
                        {this.renderToggleVariantsButton()}
                    </div>
                )
        );
    }

    renderVariants() {
        const {
            product: { group },
        } = this.props;

        if (!group?.length) {
            return null;
        }

        return (
            <div block="ProductVariants" elem="Groups">
                {this.renderMainVariants()}
                {this.renderSecondaryVariants()}
                {this.renderTertiaryVariants()}
            </div>
        );
    }

    renderContent() {
        const {
            product: { group },
        } = this.props;

        if (!group?.length) {
            return null;
        }
        const { variants } = group[0];

        if (!variants?.length) {
            return null;
        }

        return (
            <div block="ProductVariants" elem="Wrapper">
                <p block="ProductVariants" elem="Title">
                    {__('Product variants')}
                </p>
                {this.renderVariants()}
            </div>
        );
    }

    render() {
        return <>{this.renderContent()}</>;
    }
}

export default ProductVariants;
