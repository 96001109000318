import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Breadcrumbs/Query/BreadcrumbsQuery */
export class BreadcrumbsQuery {
    getBreadcrumbs(productId, categoryId) {
        return new Field('getBreadcrumbs')
            .addArgument('product_id', 'Int!', productId)
            .addArgument('search_or_link', 'Boolean!', !categoryId)
            .addArgument('category_id', 'Int', categoryId)
            .addFieldList(this._getBreadcrumbsFields());
    }

    _getBreadcrumbsFields() {
        return [
            'category_id',
            'category_link',
            'category_name',
            'option',
            'product_name',
            'product_url',
            this._getCategoryBreadCrumbsPath(),
        ];
    }

    _getCategoryBreadCrumbsPath() {
        return new Field('category_breadcrumb_path').addFieldList(['id', 'is_active', 'label', 'link']);
    }
}

export default new BreadcrumbsQuery();
