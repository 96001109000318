import { Link } from 'react-scroll';

import Icons from 'Component/Icons';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    PRODUCT_DETAILS_NAV_DURATION,
    PRODUCT_DETAILS_NAV_OFFSET,
    PRODUCT_DETAILS_NAV_OFFSET_MOBILE,
} from 'Component/ProductDetails/ProductDetails.config';
import ProductDiscountLabel from 'Component/ProductDiscountLabel';
import ProductStockAvailability from 'Component/ProductStockAvailability';
import ProductVariants from 'Component/ProductVariants';
import TextPlaceholder from 'Component/TextPlaceholder';
import { PRODUCT_ASK_ABOUT_ID } from 'Route/ProductPage/ProductPage.config';
import { ProductActions as SourceProductActions } from 'SourceComponent/ProductActions/ProductActions.component';
import { isCrawler, isSSR } from 'Util/Browser';
import { formatPrice } from 'Util/Price';

import {
    CALCULATOR_UNIT,
    FREE_DELIVERY,
    INSTALMENT_CALCULATOR_LINK,
    MINIMAL_VISIBLE_RATING_PERCENT_VAL,
    OMNIBUS_LAST_DAYS,
} from './ProductActions.config';

import './ProductActions.override.style';

/** @namespace Pwa/Component/ProductActions/Component/ProductActions */
export class ProductActions extends SourceProductActions {
    renderSchema() {
        const { stockMeta, metaLink } = this.props;

        return (
            <>
                {this.renderOfferCount()}
                <meta itemProp="availability" content={stockMeta} />
                <meta itemProp="url" content={metaLink} />
            </>
        );
    }

    renderReviewSection() {
        const {
            product: { rating_summary },
        } = this.props;

        const isRatingVisible = rating_summary > MINIMAL_VISIBLE_RATING_PERCENT_VAL;

        return (
            <div block="ProductActions" elem="Reviews">
                {isRatingVisible && this.renderRatingSummary()}
            </div>
        );
    }

    renderDiscount() {
        const {
            product: {
                discount_display,
                price_range: { maximum_price: { discount: { amount_off, percent_off = 0 } = {} } = {} } = {},
            },
            productPrice: {
                price: {
                    finalPrice: { currency },
                },
            },
            percentageDiscountTreshold,
        } = this.props;

        if (!percent_off || !amount_off || !discount_display || percent_off < percentageDiscountTreshold) {
            return null;
        }

        const fixedPrice = formatPrice(amount_off, currency);

        return (
            <div block="ProductActions" elem="Discount">
                <span block="ProductActions" elem="DiscountTitle">
                    {__('You will save')}
                </span>
                <ProductDiscountLabel
                    discountType={discount_display}
                    fixedDiscount={fixedPrice}
                    percentageDiscount={percent_off}
                />
            </div>
        );
    }

    renderQuantityBlock() {
        const {
            product: { product_unit, glue_calculator },
        } = this.props;

        if (glue_calculator) {
            return this.renderCalculatorGlue();
        }

        if (product_unit === CALCULATOR_UNIT) {
            return (
                <>
                    {this.renderCalculatorArea()}
                    {this.renderCalculatorTiles()}
                </>
            );
        }

        return this.renderQuantity();
    }

    renderAddToCartActionBlock() {
        return (
            <div block="ProductActions" elem="AddToCartWrapper" mods={{ isPrerendered: isSSR() || isCrawler() }}>
                {this.renderQuantityBlock()}
                <div block="ProductActions" elem="ButtonsWrapper">
                    {this.renderBuyNowButton()}
                    {this.renderAddToCartButton()}
                </div>
            </div>
        );
    }

    renderLowestPrice() {
        const {
            product: { lowest_price },
            device: { isMobile },
        } = this.props;

        if (!lowest_price || lowest_price === '') {
            return null;
        }
        if (isMobile) {
            return (
                <div block="ProductActions" elem="LowestPrice">
                    {__('Lowest price in the last %s days', OMNIBUS_LAST_DAYS)}.
                </div>
            );
        }

        return (
            <div block="ProductActions" elem="LowestPrice">
                {__('Lowest price in the last %s days', OMNIBUS_LAST_DAYS)}: <span>{lowest_price}</span>
            </div>
        );
    }

    renderDeliveryTime() {
        const {
            product: { stock_availability, deliverer_available, product_unit },
        } = this.props;

        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;

        return (
            <div block="ProductActions" elem="Availability">
                <ProductStockAvailability
                    stock_availability={stock_availability}
                    deliverer_available={deliverer_available}
                    isProductPage
                    isProductWithCalculator={isProductWithCalculator}
                />
            </div>
        );
    }

    renderCheapestDelivery() {
        const {
            productPrice: {
                price: {
                    finalPrice: { currency },
                },
            },
            product: { cheapest_delivery_info },
            cartPriceRules: { type = '' } = {},
        } = this.props;

        if (type === FREE_DELIVERY) {
            return (
                <div block="ProductActions" elem="FreeDelivery">
                    <span>{__('Free delivery!')}</span>
                </div>
            );
        }

        if (!cheapest_delivery_info || (cheapest_delivery_info && cheapest_delivery_info.shipping_price === null)) {
            return null;
        }

        return (
            <div block="ProductActions" elem="CheapestDelivery">
                <span>{__('Cheapest delivery')}:</span>
                <span> {formatPrice(cheapest_delivery_info.shipping_price, currency)}</span>
            </div>
        );
    }

    renderVariants() {
        const { areDetailsLoaded, product } = this.props;

        return <ProductVariants product={product} areDetailsLoaded={areDetailsLoaded} />;
    }

    renderPriceWithGlobalSchema() {
        const {
            offerType,
            areDetailsLoaded,
            product: { type_id: type },
        } = this.props;

        if (type === PRODUCT_TYPE.grouped || !areDetailsLoaded) {
            return <TextPlaceholder mix={{ block: 'ProductActions', elem: 'Placeholder', mods: { type: 'price' } }} />;
        }

        return (
            <div block="ProductActions" elem="Schema" itemType={offerType} itemProp="offers" itemScope>
                {this.renderPriceWithSchema()}
            </div>
        );
    }

    renderAskAboutProduct() {
        const { areDetailsLoaded, device: { isMobile } = {}, storePhoneNumber } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <div block="ProductActions" elem="AskAboutProduct">
                <div block="ProductActions" elem="AskAboutProductAction">
                    <Link
                        className="ProductActions-AskAboutProductButton"
                        activeClass="active"
                        to={PRODUCT_ASK_ABOUT_ID}
                        spy
                        smooth
                        duration={PRODUCT_DETAILS_NAV_DURATION}
                        offset={isMobile ? PRODUCT_DETAILS_NAV_OFFSET_MOBILE : PRODUCT_DETAILS_NAV_OFFSET}
                    >
                        <Icons name="envelope" fill="#10069F" />
                        {isMobile ? <span>{__('Ask')}</span> : <span>{__('Ask about product')}</span>}
                    </Link>
                </div>
                {isMobile && (
                    <div block="ProductActions" elem="AskAboutProductAction">
                        <a href={`tel:${storePhoneNumber}`} block="ProductActions" elem="AskAboutProductLink">
                            <Icons name="phone" fill="#10069F" />
                            <span>{__('Call')}</span>
                        </a>
                    </div>
                )}
            </div>
        );
    }

    renderInstalments() {
        const {
            areDetailsLoaded,
            product: { instalment: { value = 0, currency = '' } = {} } = {},
            installmentsEnabled,
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        if (!installmentsEnabled || !value) {
            return null;
        }

        return (
            <div block="ProductActions" elem="InstalmentsWrapper">
                <span>{__('Instalment from')}:</span>
                <span block="ProductActions" elem="InstalmentsData">
                    {formatPrice(value, currency)}
                </span>
                {this.renderInstalmentsCalculator()}
            </div>
        );
    }

    renderInstalmentsCalculator() {
        const { productPrice: { price: { finalPrice: { value = 0 } = {} } = {} } = {} } = this.props;
        return (
            <a
                href={`${INSTALMENT_CALCULATOR_LINK}&amount=${value?.toFixed(2)}`}
                block="ProductActions"
                elem="InstalmentsCalculator"
                target="_blank"
                rel="noreferrer"
                title={__('Calculate instalment')}
            >
                {__('Check!')}
            </a>
        );
    }

    renderMobile() {
        const {
            product: { type_id: type },
        } = this.props;
        const isWithoutPriceTotal = type === PRODUCT_TYPE.grouped;

        return (
            <>
                <div block="ProductActions" elem="ActionsWrapper">
                    {this.renderReviewSection()}
                </div>
                {this.renderTierPrices()}
                <div block="ProductActions" elem="ActionsWrapper" mods={{ isWithoutPriceTotal }}>
                    {this.renderPriceWithGlobalSchema()}
                </div>
                {this.renderDiscount()}
                {this.renderAddToCartActionBlock()}
                {this.renderProductAlerts()}
                {this.renderConfigurableOptions()}
                {this.renderCustomAndBundleOptions()}
                {this.renderGroupedOptions()}
                {this.renderDownloadableSamples()}
                {this.renderDownloadableLinks()}
                {this.renderVariants()}
                {this.renderDeliveryTime()}
                {this.renderCheapestDelivery()}
                {this.renderLowestPrice()}
                {this.renderAskAboutProduct()}
                {this.renderInstalments()}
            </>
        );
    }

    renderDesktop() {
        return (
            <>
                {this.renderReviewSection()}
                {this.renderConfigurableOptions()}
                {this.renderCustomAndBundleOptions()}
                {this.renderGroupedOptions()}
                {this.renderDownloadableSamples()}
                {this.renderDownloadableLinks()}
                {this.renderTierPrices()}
                {this.renderProductAlerts()}
                {this.renderPriceWithGlobalSchema()}
                {this.renderDiscount()}
                {this.renderAddToCartActionBlock()}
                {this.renderDeliveryTime()}
                {this.renderCheapestDelivery()}
                {this.renderLowestPrice()}
                {this.renderAskAboutProduct()}
                {this.renderInstalments()}
            </>
        );
    }

    render() {
        const {
            device: { isMobile } = {},
            setValidator,
            product: { product_unit },
        } = this.props;

        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;

        return (
            <article block="ProductActions" ref={(elem) => setValidator(elem)} mods={{ isProductWithCalculator }}>
                {isMobile ? this.renderMobile() : this.renderDesktop()}
            </article>
        );
    }
}

export default ProductActions;
