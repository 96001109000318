/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import SlickSlider from 'react-slick';

import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderNext from 'Component/SliderNext';
import SliderPrev from 'Component/SliderPrev';
import SliderProgressBar from 'Component/SliderProgressBar';

import {
    DEFAULT_SLICK_SETTINGS,
    SLIDES_TO_SHOW_DESKTOP,
    SLIDES_TO_SHOW_MOBILE,
    SLIDES_TO_SHOW_TABLET,
} from './AmastyRelatedProducts.config';

import './AmastyRelatedProducts.style.scss';

/** @namespace Pwa/Component/AmastyRelatedProducts/Component/AmastyRelatedProducts */
export class AmastyRelatedProducts extends Component {
    state = {
        currentSlide: 1,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    checkIfSlider() {
        const {
            items,
            device: { isMobile, isTablet },
        } = this.props;

        const isDesktop = !isMobile && !isTablet;
        const numberOfItems = items?.length;
        const showSlider =
            (isMobile && numberOfItems > SLIDES_TO_SHOW_MOBILE) ||
            (isTablet && numberOfItems > SLIDES_TO_SHOW_TABLET) ||
            (isDesktop && numberOfItems > SLIDES_TO_SHOW_DESKTOP);

        return showSlider;
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    renderSectionTitle() {
        const { block_title } = this.props;

        return (
            <h2 block="AmastyRelatedProducts" elem="SectionTitle">
                {block_title}
            </h2>
        );
    }

    renderPaging() {
        const {
            items,
            device: { isMobile },
        } = this.props;

        const { currentSlide } = this.state;
        const pages = Math?.ceil(items?.length / SLIDES_TO_SHOW_MOBILE);
        const isSlider = this.checkIfSlider();

        if (!isMobile || !isSlider) {
            return null;
        }

        return (
            <SliderCounterNav
                currentSlide={currentSlide}
                numberOfSlides={pages || 0}
                goToNextSlide={this.nextSlide}
                goToPrevSlide={this.previousSlide}
            />
        );
    }

    renderProgressBar() {
        const {
            items,
            device: { isMobile },
        } = this.props;

        const { currentSlide } = this.state;
        const pages = Math.ceil(items.length / SLIDES_TO_SHOW_MOBILE);
        const isSlider = this.checkIfSlider();

        if (!isMobile || !isSlider) {
            return null;
        }

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={pages || 0} />;
    }

    renderPageItemsBlock() {
        const { items } = this.props;

        return items.map((product, i) => (
            <ProductCard product={product} key={`amastyRelatedProduct${i}`} isNotListElement />
        ));
    }

    renderItems() {
        const { items, isLoading, settings } = this.props;
        const isSlider = this.checkIfSlider();

        if (isLoading) {
            return this.renderPlaceholderItems();
        }

        if (!items?.length && !isLoading) {
            return null;
        }

        if (isSlider) {
            return (
                <SlickSlider
                    prevArrow={<SliderPrev />}
                    nextArrow={<SliderNext />}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...settings}
                    beforeChange={(_, nextSlide) => {
                        this.setState({
                            currentSlide: nextSlide / SLIDES_TO_SHOW_MOBILE + 1,
                        });
                    }}
                    ref={(slider) => (this.slider = slider)}
                >
                    {this.renderPageItemsBlock()}
                </SlickSlider>
            );
        }

        return (
            <div block="AmastyRelatedProducts" elem="List">
                {this.renderPageItemsBlock()}
            </div>
        );
    }

    render() {
        const { items } = this.props;

        if (!items?.length) {
            return null;
        }

        return (
            <div block="AmastyRelatedProducts">
                <ContentWrapper
                    mix={{ block: 'AmastyRelatedProducts', elem: 'Content' }}
                    wrapperMix={{ block: 'AmastyRelatedProducts', elem: 'Wrapper' }}
                >
                    <div block="AmastyRelatedProducts" elem="TopWrapper">
                        {this.renderSectionTitle()}
                        {this.renderPaging()}
                    </div>
                    {this.renderItems()}
                    {this.renderProgressBar()}
                </ContentWrapper>
            </div>
        );
    }
}

export default AmastyRelatedProducts;
