/* eslint-disable no-return-assign, fp/no-let */
import { getPostUrl } from '@domneo/blog/src/util/Posts';
import React, { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import {
    BLOG_LIST_URL,
    BLOG_WIDGET_SLIDER_OPTIONS,
    BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP,
    BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE,
    BLOG_WIDGET_SLIDES_TO_SHOW_TABLET,
} from 'Component/BlogWidget/BlogWidget.config';
import Html from 'Component/Html';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderNext from 'Component/SliderNext';
import SliderPrev from 'Component/SliderPrev';
import SliderProgressBar from 'Component/SliderProgressBar';
import TextPlaceholder from 'Component/TextPlaceholder';

import './ProductPageBlogPosts.style';

/** @namespace Pwa/Component/ProductPageBlogPosts/Component/ProductPageBlogPosts */
export class ProductPageBlogPosts extends PureComponent {
    state = {
        currentSlide: 1,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    checkIfSlider() {
        const {
            device: { isMobile, isTablet },
            posts,
        } = this.props;
        const isDesktop = !isMobile && !isTablet;
        const numberOfItems = posts?.length;

        const showSlider =
            (isMobile && numberOfItems > BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE) ||
            (isTablet && numberOfItems > BLOG_WIDGET_SLIDES_TO_SHOW_TABLET) ||
            (isDesktop && numberOfItems > BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP);

        return showSlider;
    }

    getSlidesToShow() {
        const {
            device: { isMobile, isTablet },
        } = this.props;

        if (isMobile) {
            return BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE;
        }

        if (isTablet) {
            return BLOG_WIDGET_SLIDES_TO_SHOW_TABLET;
        }

        return BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP;
    }

    getNumberOfSlides() {
        const { posts } = this.props;

        return posts?.length ? Math.ceil(posts.length / this.getSlidesToShow()) : 0;
    }

    renderPaging() {
        const { currentSlide } = this.state;
        const isSlider = this.checkIfSlider();

        if (!isSlider) {
            return null;
        }

        return (
            <SliderCounterNav
                currentSlide={currentSlide}
                numberOfSlides={this.getNumberOfSlides() || 0}
                goToNextSlide={this.nextSlide}
                goToPrevSlide={this.previousSlide}
            />
        );
    }

    renderProgressBar() {
        const { currentSlide } = this.state;
        const isSlider = this.checkIfSlider();

        if (!isSlider) {
            return null;
        }

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={this.getNumberOfSlides() || 0} />;
    }

    renderContents() {
        const { posts } = this.props;
        const isSlider = this.checkIfSlider();

        if (!isSlider) {
            return (
                <div block="ProductPageBlogPosts" elem="PostsWrapper">
                    {posts?.map(this.renderCard)}
                </div>
            );
        }

        return (
            <>
                <SlickSlider
                    prevArrow={<SliderPrev />}
                    nextArrow={<SliderNext />}
                    beforeChange={(_, nextSlide) => {
                        this.setState({ currentSlide: nextSlide / this.getSlidesToShow() + 1 });
                    }}
                    ref={(slider) => (this.slider = slider)}
                    {...BLOG_WIDGET_SLIDER_OPTIONS}
                >
                    {posts?.map(this.renderCard)}
                </SlickSlider>
                {this.renderProgressBar()}
            </>
        );
    }

    renderCard(post, key) {
        const { title, first_image, short_content } = post;
        const postUrl = getPostUrl(post);

        return (
            <div block="ProductPageBlogPosts" elem="CardWrapper" key={key}>
                <div block="ProductPageBlogPosts" elem="Card">
                    <Link to={postUrl} title={title} mix={{ block: 'ProductPageBlogPosts', elem: 'ImageWrapper' }}>
                        <Image
                            src={first_image}
                            ratio="custom"
                            isPlaceholder={!first_image}
                            block="ProductPageBlogPosts"
                            elem="Image"
                        />
                    </Link>

                    <div block="ProductPageBlogPosts" elem="Content">
                        <Link to={postUrl} title={title}>
                            <h3 block="ProductPageBlogPosts" elem="PostTitle">
                                <TextPlaceholder
                                    mix={{ block: 'ProductPageBlogPosts', elem: 'PostTitlePlaceholder' }}
                                    content={title}
                                    length="short"
                                />
                            </h3>
                        </Link>

                        {!!short_content && (
                            <div block="ProductPageBlogPosts" elem="Description">
                                <Html content={short_content} />
                            </div>
                        )}
                    </div>

                    <Link to={postUrl} title={title} mix={{ block: 'ProductPageBlogPosts', elem: 'ArrowLink' }}>
                        <Icons name="arrow_right" fill="#10069F" />
                    </Link>
                </div>
            </div>
        );
    }

    renderHead() {
        const { title, hiddenSwipe } = this.props;

        return (
            <div block="ProductPageBlogPosts" elem="Head">
                <h2 block="ProductPageBlogPosts" elem="Header" mods={{ hiddenSwipe }}>
                    {title || __('Good to know')}
                </h2>
                {this.renderPaging()}
                <Link to={BLOG_LIST_URL} mix={{ block: 'ProductPageBlogPosts', elem: 'ShowAll' }}>
                    {__('Show all')}
                </Link>
            </div>
        );
    }

    render() {
        const { posts } = this.props;

        if (!posts?.length) {
            return null;
        }

        return (
            <div block="ProductPageBlogPosts">
                {this.renderHead()}
                {this.renderContents()}
            </div>
        );
    }
}

export default ProductPageBlogPosts;
