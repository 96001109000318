import { lazy, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Loader from 'Component/Loader/Loader.component';
import ProductHeader from 'Component/ProductHeader';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatchHandler from 'Route/NoMatchHandler';
import { ProductPage as SourceProductPage, ProductReviews } from 'Route/ProductPage/ProductPage.component';

import './VirtualProductPage.style';

export const ArrangementProductGallery = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
        'Component/ArrangementProductGallery'
    )
);
/** @namespace Pwa/Route/VirtualProductPage/Component/VirtualProductPage */
export class VirtualProductPage extends SourceProductPage {
    tabMap = {};

    renderDescription() {
        const {
            activeProduct: { description: { html } = {} },
            areDetailsLoaded,
        } = this.props;

        if (!html && !areDetailsLoaded) {
            return <TextPlaceholder length="long" />;
        }

        const cleanDescription = html.replace(/<\/?[^>]+(>|$)/g, '');

        return (
            <div block="ProductInformation" elem="Description">
                <meta itemProp="description" content={cleanDescription} />
                <Html content={html} />
            </div>
        );
    }

    renderProductInformation() {
        const { areDetailsLoaded } = this.props;
        const heading = areDetailsLoaded ? __('More about this project') : '';

        return (
            <div heading={heading} mix={{ block: 'ProductInformation', elem: 'Container' }}>
                <h2 block="ProductInformation" elem="Header">
                    {heading}
                </h2>
                <div block="ProductInformation" elem="Content">
                    {this.renderDescription()}
                </div>
            </div>
        );
    }

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
            quantity,
            magentoProduct,
            cartPriceRules,
        } = this.props;

        return (
            <>
                <ProductHeader
                    getLink={getLink}
                    product={dataSource}
                    parameters={parameters}
                    areDetailsLoaded={areDetailsLoaded}
                    setActiveProduct={setActiveProduct}
                    showCompareButton={false}
                />
                <Suspense fallback={<Loader />}>
                    <ArrangementProductGallery
                        product={activeProduct}
                        areDetailsLoaded={areDetailsLoaded}
                        isWithEmptySwitcher={useEmptyGallerySwitcher}
                        showLoader={isVariant}
                        getLink={getLink}
                        dataSource={dataSource}
                        parameters={parameters}
                        setActiveProduct={setActiveProduct}
                        quantity={quantity}
                        magentoProduct={magentoProduct}
                        cartPriceRules={cartPriceRules}
                    />
                </Suspense>
            </>
        );
    }

    render() {
        const { dataSource, areDetailsLoaded } = this.props;

        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                    mods={{ VirtualProductPage: true }}
                >
                    <ContentWrapper
                        wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}
                    </ContentWrapper>
                    {this.renderProductInformation()}
                    {this.renderAdditionalSections()}
                    <ProductReviews product={dataSource} areDetailsLoaded={areDetailsLoaded} />
                    {this.renderReviewPopup()}
                    {this.renderBlogPosts()}
                </main>
            </NoMatchHandler>
        );
    }
}

export default VirtualProductPage;
