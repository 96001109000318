import PropTypes from 'prop-types';

import Consents from 'Component/Consents';
import { CONSENTS_CONTACT } from 'Component/Consents/Consents.config';
import FieldForm from 'Component/FieldForm';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import ProductPrice from 'Component/ProductPrice';

import askAboutProductForm from './AskAboutProduct.form';

import './AskAboutProduct.style';

/** @namespace Pwa/Component/AskAboutProduct/Component/AskAboutProduct */
export class AskAboutProduct extends FieldForm {
    static propTypes = {
        isLoading: PropTypes.bool,
        onFormSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false,
    };

    get fieldMap() {
        return askAboutProductForm();
    }

    getFormProps() {
        const { onFormSubmit } = this.props;

        return {
            onSubmit: onFormSubmit,
        };
    }

    renderHeading() {
        return (
            <h2 block="AskAboutProduct" elem="Heading">
                {__('Ask about product')}
            </h2>
        );
    }

    renderProductName() {
        const { productName } = this.props;
        return (
            <p block="AskAboutProduct" elem="ProductName">
                {productName}
            </p>
        );
    }

    renderProductPrice() {
        const {
            productPrice,
            product: { price_range = {}, type_id: type = '', price_tiers = [], product_unit = '' } = {},
        } = this.props;

        if (!price_range) {
            return this.renderTextPlaceholder();
        }

        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;

        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    price={productPrice}
                    priceType={type}
                    tierPrices={price_tiers}
                    isProductWithCalculator={isProductWithCalculator}
                    mix={{ block: this.className, elem: 'Price' }}
                />
            </div>
        );
    }

    renderProductImage() {
        const { product: { small_image: { url = '' } = {} } = {} } = this.props;

        return (
            <div block="AskAboutProduct" elem="ImageWrapper">
                <Image src={url} alt="" ratio="custom" mix={{ block: 'AskAboutProduct', elem: 'Picture' }} />
            </div>
        );
    }

    renderProductInfo() {
        return (
            <div block="AskAboutProduct" elem="ProductInfoWrapper">
                {this.renderProductName()}
                {this.renderProductPrice()}
            </div>
        );
    }

    renderContent() {
        return (
            <>
                <div block="AskAboutProduct" elem="ProductContentWrapper">
                    {this.renderProductImage()}
                    {this.renderProductInfo()}
                </div>
                {super.render()}
            </>
        );
    }

    renderAgreements() {
        return <Consents type={CONSENTS_CONTACT} />;
    }

    renderActions() {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={isLoading} />
                <button type="submit" block="Button">
                    {__('Send')}
                </button>
            </>
        );
    }

    renderFormBody() {
        return (
            <div block="AskAboutProduct" elem="Form">
                <div block="AskAboutProduct" elem="FormWrapper">
                    {this.fieldMap.map(this.renderSection.bind(this))}
                    {this.renderAgreements()}
                    {this.renderActions()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="AskAboutProduct" id="AskAboutProduct">
                {this.renderHeading()}
                {this.renderContent()}
            </div>
        );
    }
}

export default AskAboutProduct;
