import Breadcrumbs from 'Query/Breadcrumbs.query';
import { BreadcrumbsDispatcher as SourceBreadcrumbsDispatcher } from 'SourceStore/Breadcrumbs/Breadcrumbs.dispatcher';
import { toggleBreadcrumbs, updateBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

/**
 * Breadcrumbs Dispatcher
 * @class BreadcrumbsDispatcher
 * @namespace Pwa/Store/Breadcrumbs/Dispatcher/BreadcrumbsDispatcher */
export class BreadcrumbsDispatcher extends SourceBreadcrumbsDispatcher {
    /**
     * Set breadcrumbs for category
     * @param breadcrumbs
     * @param {Function} dispatch
     * @memberof BreadcrumbsDispatcher
     */
    async updateWithProduct(product, prevCategoryId, dispatch) {
        if (product?.id) {
            try {
                const productBreadcrumbs = this.__getProductBreadcrumbsFromRequest(
                    await this.loadProductBreadcrumbs(product.id, prevCategoryId)
                );

                dispatch(toggleBreadcrumbs(true));
                dispatch(updateBreadcrumbs(productBreadcrumbs));
            } catch {
                const breadcrumbs = this._getProductBreadcrumbs(product, prevCategoryId);
                dispatch(updateBreadcrumbs(breadcrumbs));
                dispatch(toggleBreadcrumbs(true));
            }
        } else {
            dispatch(updateBreadcrumbs([]));
            dispatch(toggleBreadcrumbs(true));
        }
    }

    async loadProductBreadcrumbs(productID, prevCategoryId) {
        return executeGet(prepareQuery(Breadcrumbs.getBreadcrumbs(productID, prevCategoryId))).then(
            /** @namespace Pwa/Store/Breadcrumbs/Dispatcher/executeGet/then */
            ({ getBreadcrumbs }) => getBreadcrumbs
        );
    }

    __getProductBreadcrumbsFromRequest(breadcrumbs) {
        if (!breadcrumbs || (breadcrumbs && !breadcrumbs.product_name)) {
            return null;
        }

        const { product_name, product_url, category_breadcrumb_path } = breadcrumbs;

        return [
            { url: product_url, name: product_name },
            ...category_breadcrumb_path
                .reverse()
                .filter(({ is_active }) => is_active)
                .map(({ link, label }) => ({ url: link, name: label })),
        ];
    }

    _getProductBreadcrumbs(product, prevCategoryId = null) {
        const { categories, url, name } = product;

        if (!categories) {
            return [];
        }

        if (!categories.length) {
            return [{ url, name }];
        }

        return [
            { url, name },
            ...this._getCategoryBreadcrumbs(
                this.findCategoryById(categories, prevCategoryId) || this.findLongestBreadcrumbs(categories)
            ),
        ];
    }
}

export default new BreadcrumbsDispatcher();
