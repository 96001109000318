import {
    ARRANGEMENTS_SLIDES_TO_SHOW,
    DEFAULT_ARRANGEMENTS_SLICK_SETTINGS,
} from 'app/design/frontend/Advox/pwa/src/route/ProductPage/ProductPage.config';
import SlickSlider from 'react-slick';

import ContentWrapper from 'Component/ContentWrapper';
import Icons from 'Component/Icons';
import ProductCard from 'Component/ProductCard';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderNext from 'Component/SliderNext';
import SliderPrev from 'Component/SliderPrev';
import SliderProgressBar from 'Component/SliderProgressBar';
import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';

import {
    DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS,
    RELATED_TYPE,
    SLIDES_TO_SHOW_DESKTOP,
    SLIDES_TO_SHOW_MOBILE,
    SLIDES_TO_SHOW_TABLET,
    UPSELL_TYPE,
} from './ProductLinks.config';

import './ProductLinks.override.style';

/** @namespace Pwa/Component/ProductLinks/Component/ProductLinks */
export class ProductLinks extends SourceProductLinks {
    state = {
        currentSlide: 1,
        isSlider: false,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    componentDidMount() {
        const {
            linkType,
            linkedProducts: { related, upsell },
            device: { isMobile, isTablet },
            isArrangementPageRelated,
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let items = [];
        if (linkType === UPSELL_TYPE) {
            items = upsell?.items;
        }
        if (linkType === RELATED_TYPE) {
            items = related?.items;
        }

        const isDesktop = !isMobile && !isTablet;
        const numberOfItems = items?.length;
        const showSlider =
            (isMobile && numberOfItems > SLIDES_TO_SHOW_MOBILE) ||
            (isTablet && numberOfItems > SLIDES_TO_SHOW_TABLET) ||
            (isDesktop && numberOfItems > SLIDES_TO_SHOW_DESKTOP) ||
            (isArrangementPageRelated && numberOfItems > ARRANGEMENTS_SLIDES_TO_SHOW);

        if (showSlider) {
            this.setState({ isSlider: true });
        }
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    renderPaging() {
        const {
            linkType,
            linkedProducts: { related, upsell },
            device: { isMobile },
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let items = [];
        if (linkType === UPSELL_TYPE) {
            items = upsell?.items;
        }
        if (linkType === RELATED_TYPE) {
            items = related?.items;
        }
        const { currentSlide, isSlider } = this.state;
        const pages = Math.ceil(items.length / SLIDES_TO_SHOW_MOBILE);

        if (!isMobile || !isSlider) {
            return null;
        }

        return (
            <SliderCounterNav
                currentSlide={currentSlide}
                numberOfSlides={pages || 0}
                goToNextSlide={this.nextSlide}
                goToPrevSlide={this.previousSlide}
            />
        );
    }

    renderProgressBar() {
        const {
            linkType,
            linkedProducts: { related, upsell },
            device: { isMobile },
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let items = [];
        if (linkType === UPSELL_TYPE) {
            items = upsell?.items;
        }
        if (linkType === RELATED_TYPE) {
            items = related?.items;
        }

        const { currentSlide, isSlider } = this.state;
        const pages = Math.ceil(items.length / SLIDES_TO_SHOW_MOBILE);

        if (!isMobile || !isSlider) {
            return null;
        }

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={pages || 0} />;
    }

    renderProductCard(product, i) {
        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions,
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions,
            },
        } = this.props;
        const { id = i } = product;

        return (
            <ProductCard
                block="ProductLinks"
                elem="Card"
                product={product}
                key={id}
                mix={{ block: 'ProductLinks', elem: 'Card', mods: { hideVariants: true } }}
                siblingsHaveBrands={siblingsHaveBrands}
                siblingsHavePriceBadge={siblingsHavePriceBadge}
                siblingsHaveTierPrice={siblingsHaveTierPrice}
                siblingsHaveConfigurableOptions={siblingsHaveConfigurableOptions}
                setSiblingsHaveBrands={setSiblingsHaveBrands}
                setSiblingsHavePriceBadge={setSiblingsHavePriceBadge}
                setSiblingsHaveTierPrice={setSiblingsHaveTierPrice}
                setSiblingsHaveConfigurableOptions={setSiblingsHaveConfigurableOptions}
                hideVariants
                isNotListElement
            />
        );
    }

    renderArrangementsItems() {
        const {
            linkedProducts: { related },
            isArrangementPageRelated,
        } = this.props;

        return related.items?.map((product) => (
            <ProductCard
                product={product}
                hideVariants
                hiddenDeliveryTime
                hiddenHoverSection={isArrangementPageRelated}
                hiddenActions
                isArrangement={isArrangementPageRelated}
                hiddenOmnibus
                style={{ height: '250px' }}
                isNotListElement
            />
        ));
    }

    render() {
        const { areDetailsLoaded, settings, linkType, isArrangementPageRelated } = this.props;
        const { isSlider } = this.state;
        const isArrangementPageRelatedSection = isArrangementPageRelated && linkType === RELATED_TYPE;

        if (!areDetailsLoaded) {
            return null;
        }

        if (isArrangementPageRelatedSection) {
            if (isSlider) {
                return (
                    <ContentWrapper
                        mix={{ block: 'ProductLinks', mods: { linkType } }}
                        wrapperMix={{ block: 'ProductLinks', elem: 'Wrapper' }}
                        label={__('Linked products')}
                    >
                        <div block="RelatedBuiltProducts" elem="Wrapper">
                            <h2>{__('Linked products')}</h2>
                            {this.renderPaging()}
                            <SlickSlider
                                prevArrow={<Icons name="arrow_left" fill="#10069F" />}
                                nextArrow={<Icons name="arrow_right" fill="#10069F" />}
                                {...DEFAULT_ARRANGEMENTS_SLICK_SETTINGS}
                                {...settings}
                                beforeChange={(_, nextSlide) => {
                                    this.setState({ currentSlide: nextSlide / ARRANGEMENTS_SLIDES_TO_SHOW + 1 });
                                }}
                                // eslint-disable-next-line no-return-assign
                                ref={(slider) => (this.slider = slider)}
                            >
                                {this.renderArrangementsItems()}
                            </SlickSlider>
                            {this.renderProgressBar()}
                        </div>
                    </ContentWrapper>
                );
            }

            return (
                <ContentWrapper
                    mix={{ block: 'ProductLinks', mods: { linkType } }}
                    wrapperMix={{ block: 'ProductLinks', elem: 'Wrapper' }}
                    label={__('Linked products')}
                >
                    <div block="RelatedBuiltProducts" elem="Wrapper">
                        <h2>{__('Linked products')}</h2>
                        {this.renderPaging()}
                        <div block="RelatedBuiltProducts" elem="Box">
                            <div block="ProductLinks" elem="List">
                                {this.renderArrangementsItems()}
                            </div>
                        </div>
                        {this.renderProgressBar()}
                    </div>
                </ContentWrapper>
            );
        }

        if (isSlider) {
            return (
                <ContentWrapper
                    mix={{ block: 'ProductLinks', mods: { linkType } }}
                    wrapperMix={{ block: 'ProductLinks', elem: 'Wrapper' }}
                    label={__('Linked products')}
                >
                    {this.renderHeading()}
                    {this.renderPaging()}

                    <SlickSlider
                        prevArrow={<SliderPrev />}
                        nextArrow={<SliderNext />}
                        {...DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS}
                        {...settings}
                        beforeChange={(_, nextSlide) => {
                            this.setState({ currentSlide: nextSlide / SLIDES_TO_SHOW_MOBILE + 1 });
                        }}
                        // eslint-disable-next-line no-return-assign
                        ref={(slider) => (this.slider = slider)}
                    >
                        {this.renderItems()}
                    </SlickSlider>
                    {this.renderProgressBar()}
                </ContentWrapper>
            );
        }

        return (
            <ContentWrapper
                mix={{ block: 'ProductLinks', mods: { linkType } }}
                wrapperMix={{ block: 'ProductLinks', elem: 'Wrapper' }}
                label={__('Linked products')}
            >
                {this.renderHeading()}
                <div block="ProductLinks" elem="List">
                    {this.renderItems()}
                </div>
            </ContentWrapper>
        );
    }
}

export default ProductLinks;
